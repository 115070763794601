/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchemaColumn } from './SchemaColumn';
import {
    SchemaColumnFromJSON,
    SchemaColumnFromJSONTyped,
    SchemaColumnToJSON,
} from './SchemaColumn';
import type { SharedTableOwner } from './SharedTableOwner';
import {
    SharedTableOwnerFromJSON,
    SharedTableOwnerFromJSONTyped,
    SharedTableOwnerToJSON,
} from './SharedTableOwner';

/**
 * 
 * @export
 * @interface SharedTable
 */
export interface SharedTable {
    /**
     * 
     * @type {string}
     * @memberof SharedTable
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof SharedTable
     */
    originTableUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedTable
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof SharedTable
     */
    originWorkspaceUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedTable
     */
    createdByUuid: string;
    /**
     * 
     * @type {string}
     * @memberof SharedTable
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedTable
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedTable
     */
    groupUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedTable
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof SharedTable
     */
    isPublic: boolean;
    /**
     * 
     * @type {Array<SchemaColumn>}
     * @memberof SharedTable
     */
    schema?: Array<SchemaColumn>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SharedTable
     */
    originMapping?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof SharedTable
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SharedTable
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<SharedTableOwner>}
     * @memberof SharedTable
     */
    owners?: Array<SharedTableOwner>;
}

/**
 * Check if a given object implements the SharedTable interface.
 */
export function instanceOfSharedTable(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('createdByUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('isPublic' in value)) return false;
    return true;
}

export function SharedTableFromJSON(json: any): SharedTable {
    return SharedTableFromJSONTyped(json, false);
}

export function SharedTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedTable {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'originTableUuid': json['origin_table_uuid'] == null ? undefined : json['origin_table_uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'originWorkspaceUuid': json['origin_workspace_uuid'] == null ? undefined : json['origin_workspace_uuid'],
        'createdByUuid': json['created_by_uuid'],
        'status': json['status'] == null ? undefined : json['status'],
        'description': json['description'] == null ? undefined : json['description'],
        'groupUuid': json['group_uuid'] == null ? undefined : json['group_uuid'],
        'name': json['name'],
        'isPublic': json['is_public'],
        'schema': json['schema'] == null ? undefined : ((json['schema'] as Array<any>).map(SchemaColumnFromJSON)),
        'originMapping': json['origin_mapping'] == null ? undefined : json['origin_mapping'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'owners': json['owners'] == null ? undefined : ((json['owners'] as Array<any>).map(SharedTableOwnerFromJSON)),
    };
}

export function SharedTableToJSON(value?: SharedTable | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'origin_table_uuid': value['originTableUuid'],
        'workspace_uuid': value['workspaceUuid'],
        'origin_workspace_uuid': value['originWorkspaceUuid'],
        'created_by_uuid': value['createdByUuid'],
        'status': value['status'],
        'description': value['description'],
        'group_uuid': value['groupUuid'],
        'name': value['name'],
        'is_public': value['isPublic'],
        'schema': value['schema'] == null ? undefined : ((value['schema'] as Array<any>).map(SchemaColumnToJSON)),
        'origin_mapping': value['originMapping'],
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'owners': value['owners'] == null ? undefined : ((value['owners'] as Array<any>).map(SharedTableOwnerToJSON)),
    };
}

