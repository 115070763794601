/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FavoriteDashboardParams
 */
export interface FavoriteDashboardParams {
    /**
     * 
     * @type {boolean}
     * @memberof FavoriteDashboardParams
     */
    state: boolean;
    /**
     * 
     * @type {string}
     * @memberof FavoriteDashboardParams
     */
    dashboardUuid: string;
}

/**
 * Check if a given object implements the FavoriteDashboardParams interface.
 */
export function instanceOfFavoriteDashboardParams(value: object): boolean {
    if (!('state' in value)) return false;
    if (!('dashboardUuid' in value)) return false;
    return true;
}

export function FavoriteDashboardParamsFromJSON(json: any): FavoriteDashboardParams {
    return FavoriteDashboardParamsFromJSONTyped(json, false);
}

export function FavoriteDashboardParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteDashboardParams {
    if (json == null) {
        return json;
    }
    return {
        
        'state': json['state'],
        'dashboardUuid': json['dashboard_uuid'],
    };
}

export function FavoriteDashboardParamsToJSON(value?: FavoriteDashboardParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'state': value['state'],
        'dashboard_uuid': value['dashboardUuid'],
    };
}

