/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AssetAuthor } from './AssetAuthor';
import {
    AssetAuthorFromJSON,
    AssetAuthorFromJSONTyped,
    AssetAuthorToJSON,
} from './AssetAuthor';
import type { DisplayModel } from './DisplayModel';
import {
    DisplayModelFromJSON,
    DisplayModelFromJSONTyped,
    DisplayModelToJSON,
} from './DisplayModel';

/**
 * 
 * @export
 * @interface CommunityAsset
 */
export interface CommunityAsset {
    /**
     * 
     * @type {string}
     * @memberof CommunityAsset
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof CommunityAsset
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CommunityAsset
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommunityAsset
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CommunityAsset
     */
    likes: number;
    /**
     * 
     * @type {number}
     * @memberof CommunityAsset
     */
    usedByCount: number;
    /**
     * 
     * @type {string}
     * @memberof CommunityAsset
     */
    previewImageUrl?: string;
    /**
     * 
     * @type {AssetAuthor}
     * @memberof CommunityAsset
     */
    publishedBy: AssetAuthor | null;
    /**
     * 
     * @type {string}
     * @memberof CommunityAsset
     */
    assetType?: CommunityAssetAssetTypeEnum;
    /**
     * 
     * @type {DisplayModel}
     * @memberof CommunityAsset
     */
    asset: DisplayModel;
}


/**
 * @export
 */
export const CommunityAssetAssetTypeEnum = {
    Model: 'model'
} as const;
export type CommunityAssetAssetTypeEnum = typeof CommunityAssetAssetTypeEnum[keyof typeof CommunityAssetAssetTypeEnum];


/**
 * Check if a given object implements the CommunityAsset interface.
 */
export function instanceOfCommunityAsset(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('likes' in value)) return false;
    if (!('usedByCount' in value)) return false;
    if (!('publishedBy' in value)) return false;
    if (!('asset' in value)) return false;
    return true;
}

export function CommunityAssetFromJSON(json: any): CommunityAsset {
    return CommunityAssetFromJSONTyped(json, false);
}

export function CommunityAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunityAsset {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'likes': json['likes'],
        'usedByCount': json['used_by_count'],
        'previewImageUrl': json['preview_image_url'] == null ? undefined : json['preview_image_url'],
        'publishedBy': AssetAuthorFromJSON(json['published_by']),
        'assetType': json['asset_type'] == null ? undefined : json['asset_type'],
        'asset': DisplayModelFromJSON(json['asset']),
    };
}

export function CommunityAssetToJSON(value?: CommunityAsset | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'description': value['description'],
        'tags': value['tags'],
        'likes': value['likes'],
        'used_by_count': value['usedByCount'],
        'preview_image_url': value['previewImageUrl'],
        'published_by': AssetAuthorToJSON(value['publishedBy']),
        'asset_type': value['assetType'],
        'asset': DisplayModelToJSON(value['asset']),
    };
}

