/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartConversationParams
 */
export interface StartConversationParams {
    /**
     * 
     * @type {string}
     * @memberof StartConversationParams
     */
    context: string;
    /**
     * 
     * @type {string}
     * @memberof StartConversationParams
     */
    entityUuid?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StartConversationParams
     */
    extraData?: { [key: string]: string; };
}

/**
 * Check if a given object implements the StartConversationParams interface.
 */
export function instanceOfStartConversationParams(value: object): boolean {
    if (!('context' in value)) return false;
    return true;
}

export function StartConversationParamsFromJSON(json: any): StartConversationParams {
    return StartConversationParamsFromJSONTyped(json, false);
}

export function StartConversationParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartConversationParams {
    if (json == null) {
        return json;
    }
    return {
        
        'context': json['context'],
        'entityUuid': json['entity_uuid'] == null ? undefined : json['entity_uuid'],
        'extraData': json['extra_data'] == null ? undefined : json['extra_data'],
    };
}

export function StartConversationParamsToJSON(value?: StartConversationParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'context': value['context'],
        'entity_uuid': value['entityUuid'],
        'extra_data': value['extraData'],
    };
}

