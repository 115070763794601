/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateModelVariableDimensionValueParams
 */
export interface UpdateModelVariableDimensionValueParams {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateModelVariableDimensionValueParams
     */
    dimensionValuesPath: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UpdateModelVariableDimensionValueParams
     */
    value: string;
}

/**
 * Check if a given object implements the UpdateModelVariableDimensionValueParams interface.
 */
export function instanceOfUpdateModelVariableDimensionValueParams(value: object): boolean {
    if (!('dimensionValuesPath' in value)) return false;
    if (!('value' in value)) return false;
    return true;
}

export function UpdateModelVariableDimensionValueParamsFromJSON(json: any): UpdateModelVariableDimensionValueParams {
    return UpdateModelVariableDimensionValueParamsFromJSONTyped(json, false);
}

export function UpdateModelVariableDimensionValueParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateModelVariableDimensionValueParams {
    if (json == null) {
        return json;
    }
    return {
        
        'dimensionValuesPath': json['dimension_values_path'],
        'value': json['value'],
    };
}

export function UpdateModelVariableDimensionValueParamsToJSON(value?: UpdateModelVariableDimensionValueParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dimension_values_path': value['dimensionValuesPath'],
        'value': value['value'],
    };
}

