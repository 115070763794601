/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateSnapshotParams,
  HTTPValidationError,
  SetSnapshotOwnersParams,
  Snapshot,
} from '../models/index';
import {
    CreateSnapshotParamsFromJSON,
    CreateSnapshotParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    SetSnapshotOwnersParamsFromJSON,
    SetSnapshotOwnersParamsToJSON,
    SnapshotFromJSON,
    SnapshotToJSON,
} from '../models/index';

export interface CreateSnapshotRequest {
    workspaceCode: string;
    createSnapshotParams: CreateSnapshotParams;
}

export interface DeleteSnapshotRequest {
    snapshotUuid: string;
    workspaceCode: string;
}

export interface SetSnapshotOwnersRequest {
    snapshotUuid: string;
    workspaceCode: string;
    setSnapshotOwnersParams: SetSnapshotOwnersParams;
}

export interface UpdateSnapshotRequest {
    snapshotUuid: string;
    createSnapshotParams: CreateSnapshotParams;
}

export interface WorkspaceSnapshotsRequest {
    workspaceCode: string;
}

/**
 * 
 */
export class SnapshotsApi extends runtime.BaseAPI {

    /**
     * Create Snapshot
     */
    async createSnapshotRaw(requestParameters: CreateSnapshotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Snapshot>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createSnapshot().'
            );
        }

        if (requestParameters['createSnapshotParams'] == null) {
            throw new runtime.RequiredError(
                'createSnapshotParams',
                'Required parameter "createSnapshotParams" was null or undefined when calling createSnapshot().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/snapshots`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSnapshotParamsToJSON(requestParameters['createSnapshotParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SnapshotFromJSON(jsonValue));
    }

    /**
     * Create Snapshot
     */
    async createSnapshot(requestParameters: CreateSnapshotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Snapshot> {
        const response = await this.createSnapshotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete
     */
    async deleteSnapshotRaw(requestParameters: DeleteSnapshotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['snapshotUuid'] == null) {
            throw new runtime.RequiredError(
                'snapshotUuid',
                'Required parameter "snapshotUuid" was null or undefined when calling deleteSnapshot().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteSnapshot().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/snapshots/{snapshot_uuid}`.replace(`{${"snapshot_uuid"}}`, encodeURIComponent(String(requestParameters['snapshotUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete
     */
    async deleteSnapshot(requestParameters: DeleteSnapshotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteSnapshotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Owners
     */
    async setSnapshotOwnersRaw(requestParameters: SetSnapshotOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Snapshot>> {
        if (requestParameters['snapshotUuid'] == null) {
            throw new runtime.RequiredError(
                'snapshotUuid',
                'Required parameter "snapshotUuid" was null or undefined when calling setSnapshotOwners().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling setSnapshotOwners().'
            );
        }

        if (requestParameters['setSnapshotOwnersParams'] == null) {
            throw new runtime.RequiredError(
                'setSnapshotOwnersParams',
                'Required parameter "setSnapshotOwnersParams" was null or undefined when calling setSnapshotOwners().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/snapshots/{snapshot_uuid}/owners`.replace(`{${"snapshot_uuid"}}`, encodeURIComponent(String(requestParameters['snapshotUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetSnapshotOwnersParamsToJSON(requestParameters['setSnapshotOwnersParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SnapshotFromJSON(jsonValue));
    }

    /**
     * Set Owners
     */
    async setSnapshotOwners(requestParameters: SetSnapshotOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Snapshot> {
        const response = await this.setSnapshotOwnersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update
     */
    async updateSnapshotRaw(requestParameters: UpdateSnapshotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Snapshot>> {
        if (requestParameters['snapshotUuid'] == null) {
            throw new runtime.RequiredError(
                'snapshotUuid',
                'Required parameter "snapshotUuid" was null or undefined when calling updateSnapshot().'
            );
        }

        if (requestParameters['createSnapshotParams'] == null) {
            throw new runtime.RequiredError(
                'createSnapshotParams',
                'Required parameter "createSnapshotParams" was null or undefined when calling updateSnapshot().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/snapshots/{snapshot_uuid}`.replace(`{${"snapshot_uuid"}}`, encodeURIComponent(String(requestParameters['snapshotUuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSnapshotParamsToJSON(requestParameters['createSnapshotParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SnapshotFromJSON(jsonValue));
    }

    /**
     * Update
     */
    async updateSnapshot(requestParameters: UpdateSnapshotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Snapshot> {
        const response = await this.updateSnapshotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Workspace Snapshots
     */
    async workspaceSnapshotsRaw(requestParameters: WorkspaceSnapshotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Snapshot>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling workspaceSnapshots().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/snapshots`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SnapshotFromJSON));
    }

    /**
     * Workspace Snapshots
     */
    async workspaceSnapshots(requestParameters: WorkspaceSnapshotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Snapshot>> {
        const response = await this.workspaceSnapshotsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
