/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetDashboardContributorsParams
 */
export interface SetDashboardContributorsParams {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SetDashboardContributorsParams
     */
    contributors: { [key: string]: string; };
}


/**
 * @export
 */
export const SetDashboardContributorsParamsContributorsEnum = {
    Owner: 'owner',
    Contributor: 'contributor',
    Guest: 'guest'
} as const;
export type SetDashboardContributorsParamsContributorsEnum = typeof SetDashboardContributorsParamsContributorsEnum[keyof typeof SetDashboardContributorsParamsContributorsEnum];


/**
 * Check if a given object implements the SetDashboardContributorsParams interface.
 */
export function instanceOfSetDashboardContributorsParams(value: object): boolean {
    if (!('contributors' in value)) return false;
    return true;
}

export function SetDashboardContributorsParamsFromJSON(json: any): SetDashboardContributorsParams {
    return SetDashboardContributorsParamsFromJSONTyped(json, false);
}

export function SetDashboardContributorsParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDashboardContributorsParams {
    if (json == null) {
        return json;
    }
    return {
        
        'contributors': json['contributors'],
    };
}

export function SetDashboardContributorsParamsToJSON(value?: SetDashboardContributorsParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contributors': value['contributors'],
    };
}

