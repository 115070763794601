/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Filter } from './Filter';
import {
    FilterFromJSON,
    FilterFromJSONTyped,
    FilterToJSON,
} from './Filter';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';

/**
 * 
 * @export
 * @interface FetchUploaderDfParams
 */
export interface FetchUploaderDfParams {
    /**
     * 
     * @type {number}
     * @memberof FetchUploaderDfParams
     */
    page?: number;
    /**
     * 
     * @type {Period}
     * @memberof FetchUploaderDfParams
     */
    period: Period;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof FetchUploaderDfParams
     */
    filters?: Array<Filter>;
    /**
     * 
     * @type {string}
     * @memberof FetchUploaderDfParams
     */
    sourceType?: FetchUploaderDfParamsSourceTypeEnum;
}


/**
 * @export
 */
export const FetchUploaderDfParamsSourceTypeEnum = {
    Uploader: 'uploader',
    SharedTable: 'shared_table'
} as const;
export type FetchUploaderDfParamsSourceTypeEnum = typeof FetchUploaderDfParamsSourceTypeEnum[keyof typeof FetchUploaderDfParamsSourceTypeEnum];


/**
 * Check if a given object implements the FetchUploaderDfParams interface.
 */
export function instanceOfFetchUploaderDfParams(value: object): boolean {
    if (!('period' in value)) return false;
    return true;
}

export function FetchUploaderDfParamsFromJSON(json: any): FetchUploaderDfParams {
    return FetchUploaderDfParamsFromJSONTyped(json, false);
}

export function FetchUploaderDfParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchUploaderDfParams {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'] == null ? undefined : json['page'],
        'period': PeriodFromJSON(json['period']),
        'filters': json['filters'] == null ? undefined : ((json['filters'] as Array<any>).map(FilterFromJSON)),
        'sourceType': json['source_type'] == null ? undefined : json['source_type'],
    };
}

export function FetchUploaderDfParamsToJSON(value?: FetchUploaderDfParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'period': PeriodToJSON(value['period']),
        'filters': value['filters'] == null ? undefined : ((value['filters'] as Array<any>).map(FilterToJSON)),
        'source_type': value['sourceType'],
    };
}

