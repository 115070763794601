/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConnectionStreamOwner } from './ConnectionStreamOwner';
import {
    ConnectionStreamOwnerFromJSON,
    ConnectionStreamOwnerFromJSONTyped,
    ConnectionStreamOwnerToJSON,
} from './ConnectionStreamOwner';
import type { SchemaColumn } from './SchemaColumn';
import {
    SchemaColumnFromJSON,
    SchemaColumnFromJSONTyped,
    SchemaColumnToJSON,
} from './SchemaColumn';

/**
 * 
 * @export
 * @interface ConnectionStream
 */
export interface ConnectionStream {
    /**
     * 
     * @type {string}
     * @memberof ConnectionStream
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionStream
     */
    connectionUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionStream
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionStream
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionStream
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionStream
     */
    catalogCode: string;
    /**
     * 
     * @type {Array<SchemaColumn>}
     * @memberof ConnectionStream
     */
    schema?: Array<SchemaColumn>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionStream
     */
    values?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionStream
     */
    dimensions?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof ConnectionStream
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ConnectionStream
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionStream
     */
    isPublic: boolean;
    /**
     * 
     * @type {Array<ConnectionStreamOwner>}
     * @memberof ConnectionStream
     */
    owners?: Array<ConnectionStreamOwner>;
    /**
     * 
     * @type {Date}
     * @memberof ConnectionStream
     */
    syncedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ConnectionStream
     */
    table?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionStream
     */
    groupUuid?: string;
}

/**
 * Check if a given object implements the ConnectionStream interface.
 */
export function instanceOfConnectionStream(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('connectionUuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('catalogCode' in value)) return false;
    if (!('isPublic' in value)) return false;
    return true;
}

export function ConnectionStreamFromJSON(json: any): ConnectionStream {
    return ConnectionStreamFromJSONTyped(json, false);
}

export function ConnectionStreamFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionStream {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'connectionUuid': json['connection_uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'catalogCode': json['catalog_code'],
        'schema': json['schema'] == null ? undefined : ((json['schema'] as Array<any>).map(SchemaColumnFromJSON)),
        'values': json['values'] == null ? undefined : json['values'],
        'dimensions': json['dimensions'] == null ? undefined : json['dimensions'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'isPublic': json['is_public'],
        'owners': json['owners'] == null ? undefined : ((json['owners'] as Array<any>).map(ConnectionStreamOwnerFromJSON)),
        'syncedAt': json['synced_at'] == null ? undefined : (new Date(json['synced_at'])),
        'table': json['table'] == null ? undefined : json['table'],
        'groupUuid': json['group_uuid'] == null ? undefined : json['group_uuid'],
    };
}

export function ConnectionStreamToJSON(value?: ConnectionStream | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'connection_uuid': value['connectionUuid'],
        'workspace_uuid': value['workspaceUuid'],
        'name': value['name'],
        'description': value['description'],
        'catalog_code': value['catalogCode'],
        'schema': value['schema'] == null ? undefined : ((value['schema'] as Array<any>).map(SchemaColumnToJSON)),
        'values': value['values'],
        'dimensions': value['dimensions'],
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'is_public': value['isPublic'],
        'owners': value['owners'] == null ? undefined : ((value['owners'] as Array<any>).map(ConnectionStreamOwnerToJSON)),
        'synced_at': value['syncedAt'] == null ? undefined : ((value['syncedAt'] as any).toISOString()),
        'table': value['table'],
        'group_uuid': value['groupUuid'],
    };
}

