/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetSnapshotOwnersParams
 */
export interface SetSnapshotOwnersParams {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SetSnapshotOwnersParams
     */
    owners: { [key: string]: string; };
}

/**
 * Check if a given object implements the SetSnapshotOwnersParams interface.
 */
export function instanceOfSetSnapshotOwnersParams(value: object): boolean {
    if (!('owners' in value)) return false;
    return true;
}

export function SetSnapshotOwnersParamsFromJSON(json: any): SetSnapshotOwnersParams {
    return SetSnapshotOwnersParamsFromJSONTyped(json, false);
}

export function SetSnapshotOwnersParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetSnapshotOwnersParams {
    if (json == null) {
        return json;
    }
    return {
        
        'owners': json['owners'],
    };
}

export function SetSnapshotOwnersParamsToJSON(value?: SetSnapshotOwnersParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owners': value['owners'],
    };
}

