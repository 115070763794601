/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SchemaColumn } from './SchemaColumn';
import {
    SchemaColumnFromJSON,
    SchemaColumnFromJSONTyped,
    SchemaColumnToJSON,
} from './SchemaColumn';
import type { UploadDetails } from './UploadDetails';
import {
    UploadDetailsFromJSON,
    UploadDetailsFromJSONTyped,
    UploadDetailsToJSON,
} from './UploadDetails';
import type { UploaderOwner } from './UploaderOwner';
import {
    UploaderOwnerFromJSON,
    UploaderOwnerFromJSONTyped,
    UploaderOwnerToJSON,
} from './UploaderOwner';

/**
 * 
 * @export
 * @interface Uploader
 */
export interface Uploader {
    /**
     * 
     * @type {string}
     * @memberof Uploader
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Uploader
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof Uploader
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Uploader
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Uploader
     */
    description?: string;
    /**
     * 
     * @type {Array<UploadDetails>}
     * @memberof Uploader
     */
    uploads?: Array<UploadDetails>;
    /**
     * 
     * @type {Array<SchemaColumn>}
     * @memberof Uploader
     */
    schema?: Array<SchemaColumn>;
    /**
     * 
     * @type {Date}
     * @memberof Uploader
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Uploader
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Uploader
     */
    isPublic: boolean;
    /**
     * 
     * @type {string}
     * @memberof Uploader
     */
    groupUuid?: string;
    /**
     * 
     * @type {Array<UploaderOwner>}
     * @memberof Uploader
     */
    owners?: Array<UploaderOwner>;
}

/**
 * Check if a given object implements the Uploader interface.
 */
export function instanceOfUploader(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('code' in value)) return false;
    if (!('isPublic' in value)) return false;
    return true;
}

export function UploaderFromJSON(json: any): Uploader {
    return UploaderFromJSONTyped(json, false);
}

export function UploaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Uploader {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'name': json['name'],
        'code': json['code'],
        'description': json['description'] == null ? undefined : json['description'],
        'uploads': json['uploads'] == null ? undefined : ((json['uploads'] as Array<any>).map(UploadDetailsFromJSON)),
        'schema': json['schema'] == null ? undefined : ((json['schema'] as Array<any>).map(SchemaColumnFromJSON)),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'isPublic': json['is_public'],
        'groupUuid': json['group_uuid'] == null ? undefined : json['group_uuid'],
        'owners': json['owners'] == null ? undefined : ((json['owners'] as Array<any>).map(UploaderOwnerFromJSON)),
    };
}

export function UploaderToJSON(value?: Uploader | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'name': value['name'],
        'code': value['code'],
        'description': value['description'],
        'uploads': value['uploads'] == null ? undefined : ((value['uploads'] as Array<any>).map(UploadDetailsToJSON)),
        'schema': value['schema'] == null ? undefined : ((value['schema'] as Array<any>).map(SchemaColumnToJSON)),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'is_public': value['isPublic'],
        'group_uuid': value['groupUuid'],
        'owners': value['owners'] == null ? undefined : ((value['owners'] as Array<any>).map(UploaderOwnerToJSON)),
    };
}

