/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MappingOption } from './MappingOption';
import {
    MappingOptionFromJSON,
    MappingOptionFromJSONTyped,
    MappingOptionToJSON,
} from './MappingOption';

/**
 * 
 * @export
 * @interface CreateMappingParams
 */
export interface CreateMappingParams {
    /**
     * 
     * @type {string}
     * @memberof CreateMappingParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingParams
     */
    code?: string;
    /**
     * 
     * @type {Array<MappingOption>}
     * @memberof CreateMappingParams
     */
    options: Array<MappingOption>;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingParams
     */
    defaultValue?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMappingParams
     */
    originColumns?: Array<string>;
}

/**
 * Check if a given object implements the CreateMappingParams interface.
 */
export function instanceOfCreateMappingParams(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('options' in value)) return false;
    return true;
}

export function CreateMappingParamsFromJSON(json: any): CreateMappingParams {
    return CreateMappingParamsFromJSONTyped(json, false);
}

export function CreateMappingParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMappingParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'code': json['code'] == null ? undefined : json['code'],
        'options': ((json['options'] as Array<any>).map(MappingOptionFromJSON)),
        'defaultValue': json['default_value'] == null ? undefined : json['default_value'],
        'originColumns': json['origin_columns'] == null ? undefined : json['origin_columns'],
    };
}

export function CreateMappingParamsToJSON(value?: CreateMappingParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'code': value['code'],
        'options': ((value['options'] as Array<any>).map(MappingOptionToJSON)),
        'default_value': value['defaultValue'],
        'origin_columns': value['originColumns'],
    };
}

