/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MappingsInner } from './MappingsInner';
import {
    MappingsInnerFromJSON,
    MappingsInnerFromJSONTyped,
    MappingsInnerToJSON,
} from './MappingsInner';

/**
 * 
 * @export
 * @interface UpdateMappingGroupParams
 */
export interface UpdateMappingGroupParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateMappingGroupParams
     */
    name: string;
    /**
     * 
     * @type {Array<MappingsInner>}
     * @memberof UpdateMappingGroupParams
     */
    mappings: Array<MappingsInner>;
    /**
     * 
     * @type {string}
     * @memberof UpdateMappingGroupParams
     */
    originColumn?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMappingGroupParams
     */
    operation?: UpdateMappingGroupParamsOperationEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateMappingGroupParams
     */
    uploaderUuid?: string;
}


/**
 * @export
 */
export const UpdateMappingGroupParamsOperationEnum = {
    Eq: 'eq',
    Contains: 'contains',
    StartsWith: 'startsWith',
    EndsWith: 'endsWith',
    Neq: 'neq',
    Before: 'before',
    After: 'after'
} as const;
export type UpdateMappingGroupParamsOperationEnum = typeof UpdateMappingGroupParamsOperationEnum[keyof typeof UpdateMappingGroupParamsOperationEnum];


/**
 * Check if a given object implements the UpdateMappingGroupParams interface.
 */
export function instanceOfUpdateMappingGroupParams(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('mappings' in value)) return false;
    return true;
}

export function UpdateMappingGroupParamsFromJSON(json: any): UpdateMappingGroupParams {
    return UpdateMappingGroupParamsFromJSONTyped(json, false);
}

export function UpdateMappingGroupParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMappingGroupParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'mappings': ((json['mappings'] as Array<any>).map(MappingsInnerFromJSON)),
        'originColumn': json['origin_column'] == null ? undefined : json['origin_column'],
        'operation': json['operation'] == null ? undefined : json['operation'],
        'uploaderUuid': json['uploader_uuid'] == null ? undefined : json['uploader_uuid'],
    };
}

export function UpdateMappingGroupParamsToJSON(value?: UpdateMappingGroupParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'mappings': ((value['mappings'] as Array<any>).map(MappingsInnerToJSON)),
        'origin_column': value['originColumn'],
        'operation': value['operation'],
        'uploader_uuid': value['uploaderUuid'],
    };
}

