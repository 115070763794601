import { defineStore } from "pinia";
import { attachmentsApi as api } from "~/composables/useApi";
import { Attachment, UpdateAttachmentParams } from "~/gen";
import { ref } from "vue";

export interface AddAttachmentOptionalParams {
  description?: string;
  link?: string;
  file?: File;
}

export const useAttachmentsStore = defineStore("store/attachments", () => {
  // State
  const entitiesAttachments = ref<Record<string, Attachment[]>>({});

  // Getters
  const getEntityAttachments = (entityUuid: string) =>
    entitiesAttachments.value[entityUuid] || [];

  // Actions
  async function fetchEntityAttachments(
    workspaceCode: string,
    entityUuid: string
  ) {
    const entityAttachments = await api.getEntityAttachments({
      workspaceCode,
      entityUuid
    });
    entitiesAttachments.value[entityUuid] = entityAttachments;
    return entityAttachments;
  }

  async function addAttachmentToEntity(
    workspaceCode: string,
    entityUuid: string,
    name: string,
    { description, link, file }: AddAttachmentOptionalParams
  ) {
    const attachment = await api.addAttachmentToEntity({
      workspaceCode,
      entityUuid,
      name,
      description,
      file,
      link
    });
    entitiesAttachments.value[entityUuid] = [
      ...(entitiesAttachments.value[entityUuid] || []),
      attachment
    ];
    return attachment;
  }

  async function updateAttachment(
    workspaceCode: string,
    entityUuid: string,
    attachmentUuid: string,
    updates: UpdateAttachmentParams
  ) {
    await api.updateAttachment({
      workspaceCode,
      entityUuid,
      attachmentUuid,
      updateAttachmentParams: updates
    });
    entitiesAttachments.value[entityUuid] = (
      entitiesAttachments.value[entityUuid] || []
    ).map((a) => (a.uuid === attachmentUuid ? { ...a, ...updates } : a));
  }

  async function deleteAttachment(
    workspaceCode: string,
    entityUuid: string,
    attachmentUuid: string
  ) {
    await api.deleteAttachment({ workspaceCode, entityUuid, attachmentUuid });

    entitiesAttachments.value[entityUuid] = (
      entitiesAttachments.value[entityUuid] || []
    ).filter((a) => a.uuid != attachmentUuid);
  }

  const $reset = () => {
    entitiesAttachments.value = {};
  };

  return {
    entitiesAttachments,
    getEntityAttachments,
    fetchEntityAttachments,
    addAttachmentToEntity,
    updateAttachment,
    deleteAttachment,
    $reset
  };
});
