/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddDimensionValuesParams
 */
export interface AddDimensionValuesParams {
    /**
     * 
     * @type {string}
     * @memberof AddDimensionValuesParams
     */
    entityUuid?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AddDimensionValuesParams
     */
    attributes: { [key: string]: string; };
}

/**
 * Check if a given object implements the AddDimensionValuesParams interface.
 */
export function instanceOfAddDimensionValuesParams(value: object): boolean {
    if (!('attributes' in value)) return false;
    return true;
}

export function AddDimensionValuesParamsFromJSON(json: any): AddDimensionValuesParams {
    return AddDimensionValuesParamsFromJSONTyped(json, false);
}

export function AddDimensionValuesParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddDimensionValuesParams {
    if (json == null) {
        return json;
    }
    return {
        
        'entityUuid': json['entity_uuid'] == null ? undefined : json['entity_uuid'],
        'attributes': json['attributes'],
    };
}

export function AddDimensionValuesParamsToJSON(value?: AddDimensionValuesParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entity_uuid': value['entityUuid'],
        'attributes': value['attributes'],
    };
}

