/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Grain } from './Grain';
import {
    GrainFromJSON,
    GrainFromJSONTyped,
    GrainToJSON,
} from './Grain';

/**
 * 
 * @export
 * @interface Period
 */
export interface Period {
    /**
     * 
     * @type {Grain}
     * @memberof Period
     */
    grain?: Grain;
    /**
     * 
     * @type {Date}
     * @memberof Period
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Period
     */
    end?: Date;
}

/**
 * Check if a given object implements the Period interface.
 */
export function instanceOfPeriod(value: object): boolean {
    return true;
}

export function PeriodFromJSON(json: any): Period {
    return PeriodFromJSONTyped(json, false);
}

export function PeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): Period {
    if (json == null) {
        return json;
    }
    return {
        
        'grain': json['grain'] == null ? undefined : GrainFromJSON(json['grain']),
        'start': json['start'] == null ? undefined : (new Date(json['start'])),
        'end': json['end'] == null ? undefined : (new Date(json['end'])),
    };
}

export function PeriodToJSON(value?: Period | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'grain': GrainToJSON(value['grain']),
        'start': value['start'] == null ? undefined : ((value['start']).toISOString()),
        'end': value['end'] == null ? undefined : ((value['end']).toISOString()),
    };
}

