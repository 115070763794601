/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DimensionValue } from './DimensionValue';
import {
    DimensionValueFromJSON,
    DimensionValueFromJSONTyped,
    DimensionValueToJSON,
} from './DimensionValue';

/**
 * 
 * @export
 * @interface UpdateModelDimensionValuesParams
 */
export interface UpdateModelDimensionValuesParams {
    /**
     * 
     * @type {Array<DimensionValue>}
     * @memberof UpdateModelDimensionValuesParams
     */
    dimensionValues: Array<DimensionValue>;
}

/**
 * Check if a given object implements the UpdateModelDimensionValuesParams interface.
 */
export function instanceOfUpdateModelDimensionValuesParams(value: object): boolean {
    if (!('dimensionValues' in value)) return false;
    return true;
}

export function UpdateModelDimensionValuesParamsFromJSON(json: any): UpdateModelDimensionValuesParams {
    return UpdateModelDimensionValuesParamsFromJSONTyped(json, false);
}

export function UpdateModelDimensionValuesParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateModelDimensionValuesParams {
    if (json == null) {
        return json;
    }
    return {
        
        'dimensionValues': ((json['dimension_values'] as Array<any>).map(DimensionValueFromJSON)),
    };
}

export function UpdateModelDimensionValuesParamsToJSON(value?: UpdateModelDimensionValuesParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dimension_values': ((value['dimensionValues'] as Array<any>).map(DimensionValueToJSON)),
    };
}

