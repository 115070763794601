/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DimensionValue
 */
export interface DimensionValue {
    /**
     * 
     * @type {string}
     * @memberof DimensionValue
     */
    uuid: string;
    /**
     * 
     * @type {object}
     * @memberof DimensionValue
     */
    value: object;
    /**
     * 
     * @type {string}
     * @memberof DimensionValue
     */
    dimensionUuid: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DimensionValue
     */
    entitiesUuids?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DimensionValue
     */
    deletable?: boolean;
}

/**
 * Check if a given object implements the DimensionValue interface.
 */
export function instanceOfDimensionValue(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('value' in value)) return false;
    if (!('dimensionUuid' in value)) return false;
    return true;
}

export function DimensionValueFromJSON(json: any): DimensionValue {
    return DimensionValueFromJSONTyped(json, false);
}

export function DimensionValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): DimensionValue {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'value': json['value'],
        'dimensionUuid': json['dimension_uuid'],
        'entitiesUuids': json['entities_uuids'] == null ? undefined : json['entities_uuids'],
        'deletable': json['deletable'] == null ? undefined : json['deletable'],
    };
}

export function DimensionValueToJSON(value?: DimensionValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'value': value['value'],
        'dimension_uuid': value['dimensionUuid'],
        'entities_uuids': value['entitiesUuids'],
        'deletable': value['deletable'],
    };
}

