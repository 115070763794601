/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MappingOption } from './MappingOption';
import {
    MappingOptionFromJSON,
    MappingOptionFromJSONTyped,
    MappingOptionToJSON,
} from './MappingOption';

/**
 * 
 * @export
 * @interface MappingOutput
 */
export interface MappingOutput {
    /**
     * 
     * @type {string}
     * @memberof MappingOutput
     */
    uuid: string;
    /**
     * 
     * @type {Array<MappingOption>}
     * @memberof MappingOutput
     */
    options: Array<MappingOption>;
    /**
     * 
     * @type {string}
     * @memberof MappingOutput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MappingOutput
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof MappingOutput
     */
    mappingGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingOutput
     */
    defaultValue?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingOutput
     */
    workspaceUuid: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MappingOutput
     */
    originColumns?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MappingOutput
     */
    uploaderUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingOutput
     */
    destColumn?: string;
}

/**
 * Check if a given object implements the MappingOutput interface.
 */
export function instanceOfMappingOutput(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('options' in value)) return false;
    if (!('name' in value)) return false;
    if (!('code' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    return true;
}

export function MappingOutputFromJSON(json: any): MappingOutput {
    return MappingOutputFromJSONTyped(json, false);
}

export function MappingOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MappingOutput {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'options': ((json['options'] as Array<any>).map(MappingOptionFromJSON)),
        'name': json['name'],
        'code': json['code'],
        'mappingGroup': json['mapping_group'] == null ? undefined : json['mapping_group'],
        'defaultValue': json['default_value'] == null ? undefined : json['default_value'],
        'workspaceUuid': json['workspace_uuid'],
        'originColumns': json['origin_columns'] == null ? undefined : json['origin_columns'],
        'uploaderUuid': json['uploader_uuid'] == null ? undefined : json['uploader_uuid'],
        'destColumn': json['dest_column'] == null ? undefined : json['dest_column'],
    };
}

export function MappingOutputToJSON(value?: MappingOutput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'options': ((value['options'] as Array<any>).map(MappingOptionToJSON)),
        'name': value['name'],
        'code': value['code'],
        'mapping_group': value['mappingGroup'],
        'default_value': value['defaultValue'],
        'workspace_uuid': value['workspaceUuid'],
        'origin_columns': value['originColumns'],
        'uploader_uuid': value['uploaderUuid'],
        'dest_column': value['destColumn'],
    };
}

