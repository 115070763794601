/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SnapshotMetadata } from './SnapshotMetadata';
import {
    SnapshotMetadataFromJSON,
    SnapshotMetadataFromJSONTyped,
    SnapshotMetadataToJSON,
} from './SnapshotMetadata';
import type { SnapshotOwner } from './SnapshotOwner';
import {
    SnapshotOwnerFromJSON,
    SnapshotOwnerFromJSONTyped,
    SnapshotOwnerToJSON,
} from './SnapshotOwner';

/**
 * 
 * @export
 * @interface Snapshot
 */
export interface Snapshot {
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    uuid: string;
    /**
     * 
     * @type {SnapshotMetadata}
     * @memberof Snapshot
     */
    metadata: SnapshotMetadata;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    workspaceUuid: string | null;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Snapshot
     */
    isPublic: boolean;
    /**
     * 
     * @type {Array<SnapshotOwner>}
     * @memberof Snapshot
     */
    owners?: Array<SnapshotOwner>;
    /**
     * 
     * @type {Date}
     * @memberof Snapshot
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Snapshot
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the Snapshot interface.
 */
export function instanceOfSnapshot(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('metadata' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('isPublic' in value)) return false;
    return true;
}

export function SnapshotFromJSON(json: any): Snapshot {
    return SnapshotFromJSONTyped(json, false);
}

export function SnapshotFromJSONTyped(json: any, ignoreDiscriminator: boolean): Snapshot {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'metadata': SnapshotMetadataFromJSON(json['metadata']),
        'workspaceUuid': json['workspace_uuid'],
        'name': json['name'],
        'isPublic': json['is_public'],
        'owners': json['owners'] == null ? undefined : ((json['owners'] as Array<any>).map(SnapshotOwnerFromJSON)),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
    };
}

export function SnapshotToJSON(value?: Snapshot | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'metadata': SnapshotMetadataToJSON(value['metadata']),
        'workspace_uuid': value['workspaceUuid'],
        'name': value['name'],
        'is_public': value['isPublic'],
        'owners': value['owners'] == null ? undefined : ((value['owners'] as Array<any>).map(SnapshotOwnerToJSON)),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
    };
}

