/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Database relationship model.
 * @export
 * @interface DatabaseRelationship
 */
export interface DatabaseRelationship {
    /**
     * 
     * @type {string}
     * @memberof DatabaseRelationship
     */
    sourceType: DatabaseRelationshipSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DatabaseRelationship
     */
    sourceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseRelationship
     */
    databaseUuid: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DatabaseRelationship
     */
    columnsMapping?: { [key: string]: string; };
}


/**
 * @export
 */
export const DatabaseRelationshipSourceTypeEnum = {
    Connection: 'connection',
    Uploader: 'uploader',
    Database: 'database',
    SharedTable: 'shared_table'
} as const;
export type DatabaseRelationshipSourceTypeEnum = typeof DatabaseRelationshipSourceTypeEnum[keyof typeof DatabaseRelationshipSourceTypeEnum];


/**
 * Check if a given object implements the DatabaseRelationship interface.
 */
export function instanceOfDatabaseRelationship(value: object): boolean {
    if (!('sourceType' in value)) return false;
    if (!('sourceUuid' in value)) return false;
    if (!('databaseUuid' in value)) return false;
    return true;
}

export function DatabaseRelationshipFromJSON(json: any): DatabaseRelationship {
    return DatabaseRelationshipFromJSONTyped(json, false);
}

export function DatabaseRelationshipFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseRelationship {
    if (json == null) {
        return json;
    }
    return {
        
        'sourceType': json['source_type'],
        'sourceUuid': json['source_uuid'],
        'databaseUuid': json['database_uuid'],
        'columnsMapping': json['columns_mapping'] == null ? undefined : json['columns_mapping'],
    };
}

export function DatabaseRelationshipToJSON(value?: DatabaseRelationship | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'source_type': value['sourceType'],
        'source_uuid': value['sourceUuid'],
        'database_uuid': value['databaseUuid'],
        'columns_mapping': value['columnsMapping'],
    };
}

