/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariableExpression
 */
export interface VariableExpression {
    /**
     * 
     * @type {string}
     * @memberof VariableExpression
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof VariableExpression
     */
    formula?: string;
    /**
     * 
     * @type {Date}
     * @memberof VariableExpression
     */
    periodStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof VariableExpression
     */
    periodEnd: Date;
    /**
     * 
     * @type {string}
     * @memberof VariableExpression
     */
    variableUuid: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VariableExpression
     */
    dimensionsValues?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof VariableExpression
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VariableExpression
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof VariableExpression
     */
    versionUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof VariableExpression
     */
    scenarioUuid?: string;
}

/**
 * Check if a given object implements the VariableExpression interface.
 */
export function instanceOfVariableExpression(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('periodStart' in value)) return false;
    if (!('periodEnd' in value)) return false;
    if (!('variableUuid' in value)) return false;
    return true;
}

export function VariableExpressionFromJSON(json: any): VariableExpression {
    return VariableExpressionFromJSONTyped(json, false);
}

export function VariableExpressionFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableExpression {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'formula': json['formula'] == null ? undefined : json['formula'],
        'periodStart': (new Date(json['period_start'])),
        'periodEnd': (new Date(json['period_end'])),
        'variableUuid': json['variable_uuid'],
        'dimensionsValues': json['dimensions_values'] == null ? undefined : json['dimensions_values'],
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'versionUuid': json['version_uuid'] == null ? undefined : json['version_uuid'],
        'scenarioUuid': json['scenario_uuid'] == null ? undefined : json['scenario_uuid'],
    };
}

export function VariableExpressionToJSON(value?: VariableExpression | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'formula': value['formula'],
        'period_start': ((value['periodStart']).toISOString()),
        'period_end': ((value['periodEnd']).toISOString()),
        'variable_uuid': value['variableUuid'],
        'dimensions_values': value['dimensionsValues'],
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt'] as any).toISOString()),
        'version_uuid': value['versionUuid'],
        'scenario_uuid': value['scenarioUuid'],
    };
}

