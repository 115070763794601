import { Datasource, NavFolder, useAuthApi, useWorkspaceApi } from "~/composables/useApi";
import { ConnectionStream, Database, Metric, Model, ModelContributor, ModelContributorRoleEnum, ModelVersion, ModelVersionContributor, ModelVersionContributorRoleEnum, Dashboard, DashboardContributor, UpdateConnectionStreamDetailsRequest, Uploader, User } from "~/gen";

type Action = "read" | "edit" | "create" | "insertValues" | "contribute"
type Resource = "scenarios" | "metrics" | "dashboards" | "settings" | "datasources" | "folders" | "models" | 'modelVersions' 
type CanParamsKeyType = "dashboard" | "metric" | "datasource" | "folder" | 'model' | 'modelVersion';

type DashboardContributorRoleType = "owner" | "contributor" | "guest";

type ResourceTypes = Metric | Dashboard | Model | ModelVersion | NavFolder | Datasource;

export async function getRoleAsync(user: User, wsCode?: string) {
  const wsApi = useWorkspaceApi();
  await wsApi.current(wsCode);
  await wsApi.fetchUsers(wsCode);
  return wsApi.getUserRole(user.uuid);
}

export function getRole(user: User) {
  return useWorkspaceApi().getUserRole(user.uuid);
}

export const can = (action: Action, resource: Resource, params?: Partial<Record<CanParamsKeyType, ResourceTypes>>): boolean => {
  const user  = useAuthApi().user;
  if (user?.isAdmin) return true; // XXX This should be handled better
  const wsRole = getRole(user as User); // only non-guests can create
  if (action === "create") {
    return wsRole !== undefined && wsRole !== 'guest';
  }

  switch (resource) {
    case "datasources": {
      const { datasource } = params as {datasource: Uploader | ConnectionStream | Database}; // XXX Should be Datasource, not Uploader?
      if (!datasource) return false;
      if (wsRole === "admin") return true;
      if (action === "edit") { // only owners can edit metrics
        return !!datasource.owners?.find(o => o.userUuid === user?.uuid);
      } else if (action === "read") { // guests can read 
        return datasource.isPublic || !!datasource.owners?.find(o => o.userUuid === user?.uuid);
      } 
      break;
    }
    case "metrics": {
      const { metric } = params as {metric: Metric};
      if (!metric) return false;
      if (wsRole === "admin") return true;
      if (action === "edit") { // only owners can edit metrics
        return !!metric.owners?.find(o => o.userUuid === user?.uuid);
      } else if (action === "read") { // guests can read 
        return metric.isPublic || !!metric.owners?.find(o => o.userUuid === user?.uuid);
      }
      break;
    }
    case "dashboards": {
      const { dashboard: dashboard } = params as {dashboard: Dashboard} ;
      if (!dashboard) return false;
      if (wsRole === "admin") return true;
      const role = dashboard.contributors?.find((o: DashboardContributor) => o.userUuid === user?.uuid)?.name as DashboardContributorRoleType;
      if (action === "edit") { // only owners can edit
        return role === "owner";
      } else if (action === "insertValues") { // contributors can insert values
        return role === "owner" || role === "contributor";
      } else if (action === "read") { // guests can read 
        return dashboard.isPublic || (role && (role === "owner" || role === "contributor" || role === "guest"));
      }
      break;
    }
    case "folders": {
      const { folder } = params as { folder: NavFolder };
      if (wsRole === "admin") return true; // Admins can do all
      if (action === "edit") {
        if (wsRole === "guest") return false; // Guests can edit
        if (folder.createdByUuid === user?.uuid) return true; // Creator can edit
        return false; // Non-creator regular user cannot edit
      } 
      break;
    }
    case "scenarios": {
      const role = getRole(user as User); // only non-guests can create
      return role !== undefined && role !== 'guest';// for now only non-guests can edit scenarios
    }
    case "settings": {
      const role = getRole(user as User); // only non-guests can create
      return role !== undefined && role !== 'guest'; // for now only non-guests can deal with settings
    }
    case "models": {
      const {model} = params as { model: Model };
      if (!model) return false;
      if (wsRole === "admin") return true;
      if (model.authorUuid === user?.uuid) return true;
      const role = model.contributors.find((c: ModelContributor) => c.userUuid === user?.uuid)?.role as ModelContributorRoleEnum;
      if (action === "edit") { // only owners can edit
        return role === "owner";
      } else if (action === 'contribute') {
        return ["owner", "contributor"].includes(role);
      } else if (action === "read") { // guests can read 
        return model.isPublic || (!!role);
      }
      return false;
    }
    case "modelVersions": {
      const {model,modelVersion} = params as { model: Model, modelVersion: ModelVersion };
      if (!modelVersion) return false;
      if (wsRole === "admin") return true;
      if (modelVersion.modelAuthorUuid === user?.uuid) return true;
      if (modelVersion.versionAuthorUuid === user?.uuid) return true;
      const role = model.contributors.find(
        (c: ModelContributor) => c.userUuid === user?.uuid
      )?.role as ModelVersionContributorRoleEnum;

      if (action === "edit") { // only owners can edit
        return role === "owner";
      } else if (action === 'contribute') {
        return ["owner", "contributor"].includes(role);
      } else if (action === "read") { // guests can read 
        return model.isPublic || (!!role);
      }
      return false;
    }
  }
  return false;
};
