/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FavoriteSharedTableParams
 */
export interface FavoriteSharedTableParams {
    /**
     * 
     * @type {boolean}
     * @memberof FavoriteSharedTableParams
     */
    favorited: boolean;
}

/**
 * Check if a given object implements the FavoriteSharedTableParams interface.
 */
export function instanceOfFavoriteSharedTableParams(value: object): boolean {
    if (!('favorited' in value)) return false;
    return true;
}

export function FavoriteSharedTableParamsFromJSON(json: any): FavoriteSharedTableParams {
    return FavoriteSharedTableParamsFromJSONTyped(json, false);
}

export function FavoriteSharedTableParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteSharedTableParams {
    if (json == null) {
        return json;
    }
    return {
        
        'favorited': json['favorited'],
    };
}

export function FavoriteSharedTableParamsToJSON(value?: FavoriteSharedTableParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'favorited': value['favorited'],
    };
}

