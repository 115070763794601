/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Dashboard } from './Dashboard';
import {
    DashboardFromJSON,
    DashboardFromJSONTyped,
    DashboardToJSON,
} from './Dashboard';
import type { TablesData } from './TablesData';
import {
    TablesDataFromJSON,
    TablesDataFromJSONTyped,
    TablesDataToJSON,
} from './TablesData';

/**
 * 
 * @export
 * @interface DashboardDetailsResponse
 */
export interface DashboardDetailsResponse {
    /**
     * 
     * @type {Dashboard}
     * @memberof DashboardDetailsResponse
     */
    dashboard: Dashboard;
    /**
     * 
     * @type {{ [key: string]: TablesData; }}
     * @memberof DashboardDetailsResponse
     */
    tablesData: { [key: string]: TablesData; };
}

/**
 * Check if a given object implements the DashboardDetailsResponse interface.
 */
export function instanceOfDashboardDetailsResponse(value: object): boolean {
    if (!('dashboard' in value)) return false;
    if (!('tablesData' in value)) return false;
    return true;
}

export function DashboardDetailsResponseFromJSON(json: any): DashboardDetailsResponse {
    return DashboardDetailsResponseFromJSONTyped(json, false);
}

export function DashboardDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'dashboard': DashboardFromJSON(json['dashboard']),
        'tablesData': (mapValues(json['tables_data'], TablesDataFromJSON)),
    };
}

export function DashboardDetailsResponseToJSON(value?: DashboardDetailsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dashboard': DashboardToJSON(value['dashboard']),
        'tables_data': (mapValues(value['tablesData'], TablesDataToJSON)),
    };
}

