/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AskQuestionResponse,
  HTTPValidationError,
  StartConversationParams,
} from '../models/index';
import {
    AskQuestionResponseFromJSON,
    AskQuestionResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    StartConversationParamsFromJSON,
    StartConversationParamsToJSON,
} from '../models/index';

export interface RegenerateLastAnswerRequest {
    workspaceCode: string;
    conversationUuid: string;
}

export interface StartConversationRequest {
    workspaceCode: string;
    startConversationParams: StartConversationParams;
}

/**
 * 
 */
export class AiApi extends runtime.BaseAPI {

    /**
     * Regenerate Last Answer
     */
    async regenerateLastAnswerRaw(requestParameters: RegenerateLastAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AskQuestionResponse>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling regenerateLastAnswer().'
            );
        }

        if (requestParameters['conversationUuid'] == null) {
            throw new runtime.RequiredError(
                'conversationUuid',
                'Required parameter "conversationUuid" was null or undefined when calling regenerateLastAnswer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['conversationUuid'] != null) {
            queryParameters['conversation_uuid'] = requestParameters['conversationUuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/ai/regenerate`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AskQuestionResponseFromJSON(jsonValue));
    }

    /**
     * Regenerate Last Answer
     */
    async regenerateLastAnswer(requestParameters: RegenerateLastAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AskQuestionResponse> {
        const response = await this.regenerateLastAnswerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Start Conversation
     */
    async startConversationRaw(requestParameters: StartConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling startConversation().'
            );
        }

        if (requestParameters['startConversationParams'] == null) {
            throw new runtime.RequiredError(
                'startConversationParams',
                'Required parameter "startConversationParams" was null or undefined when calling startConversation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/ai/chat`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartConversationParamsToJSON(requestParameters['startConversationParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Start Conversation
     */
    async startConversation(requestParameters: StartConversationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.startConversationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
