/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SharedTableOwner
 */
export interface SharedTableOwner {
    /**
     * 
     * @type {string}
     * @memberof SharedTableOwner
     */
    sharedTableUuid: string;
    /**
     * 
     * @type {string}
     * @memberof SharedTableOwner
     */
    userUuid: string;
}

/**
 * Check if a given object implements the SharedTableOwner interface.
 */
export function instanceOfSharedTableOwner(value: object): boolean {
    if (!('sharedTableUuid' in value)) return false;
    if (!('userUuid' in value)) return false;
    return true;
}

export function SharedTableOwnerFromJSON(json: any): SharedTableOwner {
    return SharedTableOwnerFromJSONTyped(json, false);
}

export function SharedTableOwnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedTableOwner {
    if (json == null) {
        return json;
    }
    return {
        
        'sharedTableUuid': json['shared_table_uuid'],
        'userUuid': json['user_uuid'],
    };
}

export function SharedTableOwnerToJSON(value?: SharedTableOwner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shared_table_uuid': value['sharedTableUuid'],
        'user_uuid': value['userUuid'],
    };
}

