/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDatabaseParams
 */
export interface CreateDatabaseParams {
    /**
     * 
     * @type {string}
     * @memberof CreateDatabaseParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatabaseParams
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatabaseParams
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatabaseParams
     */
    groupUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatabaseParams
     */
    sourceUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDatabaseParams
     */
    sourceType?: CreateDatabaseParamsSourceTypeEnum;
}


/**
 * @export
 */
export const CreateDatabaseParamsSourceTypeEnum = {
    Connection: 'connection',
    Uploader: 'uploader',
    Database: 'database',
    SharedTable: 'shared_table'
} as const;
export type CreateDatabaseParamsSourceTypeEnum = typeof CreateDatabaseParamsSourceTypeEnum[keyof typeof CreateDatabaseParamsSourceTypeEnum];


/**
 * Check if a given object implements the CreateDatabaseParams interface.
 */
export function instanceOfCreateDatabaseParams(value: object): boolean {
    if (!('name' in value)) return false;
    return true;
}

export function CreateDatabaseParamsFromJSON(json: any): CreateDatabaseParams {
    return CreateDatabaseParamsFromJSONTyped(json, false);
}

export function CreateDatabaseParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDatabaseParams {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'code': json['code'] == null ? undefined : json['code'],
        'groupUuid': json['group_uuid'] == null ? undefined : json['group_uuid'],
        'sourceUuid': json['source_uuid'] == null ? undefined : json['source_uuid'],
        'sourceType': json['source_type'] == null ? undefined : json['source_type'],
    };
}

export function CreateDatabaseParamsToJSON(value?: CreateDatabaseParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'code': value['code'],
        'group_uuid': value['groupUuid'],
        'source_uuid': value['sourceUuid'],
        'source_type': value['sourceType'],
    };
}

