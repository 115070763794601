/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateConnectionParams
 */
export interface CreateConnectionParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateConnectionParams
     */
    metricIds: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CreateConnectionParams
     */
    _configuration: object;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionParams
     */
    connectionUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionParams
     */
    linkToken?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionParams
     */
    provider: CreateConnectionParamsProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateConnectionParams
     */
    connectorType: CreateConnectionParamsConnectorTypeEnum;
}


/**
 * @export
 */
export const CreateConnectionParamsProviderEnum = {
    Merge: 'merge'
} as const;
export type CreateConnectionParamsProviderEnum = typeof CreateConnectionParamsProviderEnum[keyof typeof CreateConnectionParamsProviderEnum];

/**
 * @export
 */
export const CreateConnectionParamsConnectorTypeEnum = {
    Custom: 'custom',
    Hris: 'hris',
    Accounting: 'accounting',
    Ats: 'ats',
    Crm: 'crm',
    Ticketing: 'ticketing'
} as const;
export type CreateConnectionParamsConnectorTypeEnum = typeof CreateConnectionParamsConnectorTypeEnum[keyof typeof CreateConnectionParamsConnectorTypeEnum];


/**
 * Check if a given object implements the CreateConnectionParams interface.
 */
export function instanceOfCreateConnectionParams(value: object): boolean {
    if (!('metricIds' in value)) return false;
    if (!('_configuration' in value)) return false;
    if (!('provider' in value)) return false;
    if (!('connectorType' in value)) return false;
    return true;
}

export function CreateConnectionParamsFromJSON(json: any): CreateConnectionParams {
    return CreateConnectionParamsFromJSONTyped(json, false);
}

export function CreateConnectionParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateConnectionParams {
    if (json == null) {
        return json;
    }
    return {
        
        'metricIds': json['metric_ids'],
        '_configuration': json['configuration'],
        'connectionUuid': json['connection_uuid'] == null ? undefined : json['connection_uuid'],
        'linkToken': json['link_token'] == null ? undefined : json['link_token'],
        'provider': json['provider'],
        'connectorType': json['connector_type'],
    };
}

export function CreateConnectionParamsToJSON(value?: CreateConnectionParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'metric_ids': value['metricIds'],
        'configuration': value['_configuration'],
        'connection_uuid': value['connectionUuid'],
        'link_token': value['linkToken'],
        'provider': value['provider'],
        'connector_type': value['connectorType'],
    };
}

