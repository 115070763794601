/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddMetricToTableParams,
  CreateDashboardTableParams,
  Dashboard,
  DashboardDetailsResponse,
  DownloadCSVParams,
  DownloadTableXlsParams,
  DuplicateDashboardParams,
  DuplicateDashboardResponse,
  DuplicateTableParams,
  FavoriteDashboardParams,
  FavoriteSharedTableParams,
  HTTPValidationError,
  NewDashboardParams,
  SetDashboardContributorsParams,
  SetSharedTableOwnersParams,
  ShareTableParams,
  SharedTable,
  Table,
  TableDataParams,
  TableDataResponse,
  UpdateDashboardParams,
  UpdateSchemaParams,
  UpdateSharedTableDetailsParams,
  UpdateTableAttributesParams,
} from '../models/index';
import {
    AddMetricToTableParamsFromJSON,
    AddMetricToTableParamsToJSON,
    CreateDashboardTableParamsFromJSON,
    CreateDashboardTableParamsToJSON,
    DashboardFromJSON,
    DashboardToJSON,
    DashboardDetailsResponseFromJSON,
    DashboardDetailsResponseToJSON,
    DownloadCSVParamsFromJSON,
    DownloadCSVParamsToJSON,
    DownloadTableXlsParamsFromJSON,
    DownloadTableXlsParamsToJSON,
    DuplicateDashboardParamsFromJSON,
    DuplicateDashboardParamsToJSON,
    DuplicateDashboardResponseFromJSON,
    DuplicateDashboardResponseToJSON,
    DuplicateTableParamsFromJSON,
    DuplicateTableParamsToJSON,
    FavoriteDashboardParamsFromJSON,
    FavoriteDashboardParamsToJSON,
    FavoriteSharedTableParamsFromJSON,
    FavoriteSharedTableParamsToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    NewDashboardParamsFromJSON,
    NewDashboardParamsToJSON,
    SetDashboardContributorsParamsFromJSON,
    SetDashboardContributorsParamsToJSON,
    SetSharedTableOwnersParamsFromJSON,
    SetSharedTableOwnersParamsToJSON,
    ShareTableParamsFromJSON,
    ShareTableParamsToJSON,
    SharedTableFromJSON,
    SharedTableToJSON,
    TableFromJSON,
    TableToJSON,
    TableDataParamsFromJSON,
    TableDataParamsToJSON,
    TableDataResponseFromJSON,
    TableDataResponseToJSON,
    UpdateDashboardParamsFromJSON,
    UpdateDashboardParamsToJSON,
    UpdateSchemaParamsFromJSON,
    UpdateSchemaParamsToJSON,
    UpdateSharedTableDetailsParamsFromJSON,
    UpdateSharedTableDetailsParamsToJSON,
    UpdateTableAttributesParamsFromJSON,
    UpdateTableAttributesParamsToJSON,
} from '../models/index';

export interface AddMetricToTableRequest {
    dashboardUuid: string;
    tableUuid: string;
    workspaceCode: string;
    addMetricToTableParams: AddMetricToTableParams;
}

export interface CreateDashboardTableRequest {
    dashboardUuid: string;
    workspaceCode: string;
    createDashboardTableParams: CreateDashboardTableParams;
}

export interface CreateNewDashboardRequest {
    workspaceCode: string;
    newDashboardParams: NewDashboardParams;
}

export interface DeleteDashboardRequest {
    dashboardUuid: string;
    workspaceCode: string;
}

export interface DeleteDashboardTableRequest {
    dashboardUuid: string;
    tableUuid: string;
    workspaceCode: string;
}

export interface DeleteSharedTableRequest {
    tableUuid: string;
    workspaceCode: string;
}

export interface DownloadSharedTableAsCsvRequest {
    workspaceCode: string;
    tableUuid: string;
    downloadCSVParams: DownloadCSVParams;
}

export interface DownloadTableCSVRequest {
    tableUuid: string;
    workspaceCode: string;
}

export interface DownloadTableXLSRequest {
    tableUuid: string;
    workspaceCode: string;
    downloadTableXlsParams: DownloadTableXlsParams;
}

export interface DuplicateDashboardRequest {
    dashboardUuid: string;
    workspaceCode: string;
    duplicateDashboardParams: DuplicateDashboardParams;
}

export interface DuplicateTableRequest {
    dashboardUuid: string;
    tableUuid: string;
    workspaceCode: string;
    duplicateTableParams: DuplicateTableParams;
}

export interface FavoriteSharedTableRequest {
    workspaceCode: string;
    tableUuid: string;
    favoriteSharedTableParams: FavoriteSharedTableParams;
}

export interface FetchDashboardDetailsRequest {
    dashboardUuid: string;
    workspaceCode: string;
    includeTablesData?: boolean;
    snapshotUuid?: string;
}

export interface FetchTableDataRequest {
    tableUuid: string;
    workspaceCode: string;
    tableDataParams: TableDataParams;
}

export interface ListSharedTablesFromOriginTableRequest {
    tableUuid: string;
}

export interface ListWorkspaceDashboardsRequest {
    workspaceCode: string;
}

export interface ListWorkspaceSharedTablesRequest {
    workspaceCode: string;
}

export interface RemoveMetricFromTableRequest {
    dashboardUuid: string;
    tableUuid: string;
    workspaceCode: string;
    addMetricToTableParams: AddMetricToTableParams;
}

export interface SetDashboardContributorsRequest {
    dashboardUuid: string;
    workspaceCode: string;
    setDashboardContributorsParams: SetDashboardContributorsParams;
}

export interface SetSharedTableOwnersRequest {
    tableUuid: string;
    workspaceCode: string;
    setSharedTableOwnersParams: SetSharedTableOwnersParams;
}

export interface ShareTableRequest {
    workspaceCode: string;
    tableUuid: string;
    shareTableParams: ShareTableParams;
}

export interface SyncSharedTableRequest {
    tableUuid: string;
    workspaceCode: string;
}

export interface ToggleFavoriteRequest {
    workspaceCode: string;
    favoriteDashboardParams: FavoriteDashboardParams;
}

export interface UpdateDashboardRequest {
    dashboardUuid: string;
    workspaceCode: string;
    updateDashboardParams: UpdateDashboardParams;
}

export interface UpdateSharedTableDetailsRequest {
    workspaceCode: string;
    tableUuid: string;
    updateSharedTableDetailsParams: UpdateSharedTableDetailsParams;
}

export interface UpdateSharedTableSchemaRequest {
    workspaceCode: string;
    tableUuid: string;
    updateSchemaParams: UpdateSchemaParams;
}

export interface UpdateTableAttributesRequest {
    tableUuid: string;
    workspaceCode: string;
    updateTableAttributesParams: UpdateTableAttributesParams;
}

/**
 * 
 */
export class DashboardsApi extends runtime.BaseAPI {

    /**
     * Add Metric To Table
     */
    async addMetricToTableRaw(requestParameters: AddMetricToTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TableDataResponse>> {
        if (requestParameters['dashboardUuid'] == null) {
            throw new runtime.RequiredError(
                'dashboardUuid',
                'Required parameter "dashboardUuid" was null or undefined when calling addMetricToTable().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling addMetricToTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling addMetricToTable().'
            );
        }

        if (requestParameters['addMetricToTableParams'] == null) {
            throw new runtime.RequiredError(
                'addMetricToTableParams',
                'Required parameter "addMetricToTableParams" was null or undefined when calling addMetricToTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/{dashboard_uuid}/tables/{table_uuid}/metrics`.replace(`{${"dashboard_uuid"}}`, encodeURIComponent(String(requestParameters['dashboardUuid']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMetricToTableParamsToJSON(requestParameters['addMetricToTableParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TableDataResponseFromJSON(jsonValue));
    }

    /**
     * Add Metric To Table
     */
    async addMetricToTable(requestParameters: AddMetricToTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TableDataResponse> {
        const response = await this.addMetricToTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Table To Dashboard
     */
    async createDashboardTableRaw(requestParameters: CreateDashboardTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Table>> {
        if (requestParameters['dashboardUuid'] == null) {
            throw new runtime.RequiredError(
                'dashboardUuid',
                'Required parameter "dashboardUuid" was null or undefined when calling createDashboardTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createDashboardTable().'
            );
        }

        if (requestParameters['createDashboardTableParams'] == null) {
            throw new runtime.RequiredError(
                'createDashboardTableParams',
                'Required parameter "createDashboardTableParams" was null or undefined when calling createDashboardTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/{dashboard_uuid}/tables`.replace(`{${"dashboard_uuid"}}`, encodeURIComponent(String(requestParameters['dashboardUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDashboardTableParamsToJSON(requestParameters['createDashboardTableParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TableFromJSON(jsonValue));
    }

    /**
     * Add Table To Dashboard
     */
    async createDashboardTable(requestParameters: CreateDashboardTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Table> {
        const response = await this.createDashboardTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create New Dashboard
     */
    async createNewDashboardRaw(requestParameters: CreateNewDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Dashboard>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling createNewDashboard().'
            );
        }

        if (requestParameters['newDashboardParams'] == null) {
            throw new runtime.RequiredError(
                'newDashboardParams',
                'Required parameter "newDashboardParams" was null or undefined when calling createNewDashboard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewDashboardParamsToJSON(requestParameters['newDashboardParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardFromJSON(jsonValue));
    }

    /**
     * Create New Dashboard
     */
    async createNewDashboard(requestParameters: CreateNewDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Dashboard> {
        const response = await this.createNewDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Dashboard
     */
    async deleteDashboardRaw(requestParameters: DeleteDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['dashboardUuid'] == null) {
            throw new runtime.RequiredError(
                'dashboardUuid',
                'Required parameter "dashboardUuid" was null or undefined when calling deleteDashboard().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteDashboard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/delete/{dashboard_uuid}`.replace(`{${"dashboard_uuid"}}`, encodeURIComponent(String(requestParameters['dashboardUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Dashboard
     */
    async deleteDashboard(requestParameters: DeleteDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Table From Dashboard
     */
    async deleteDashboardTableRaw(requestParameters: DeleteDashboardTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['dashboardUuid'] == null) {
            throw new runtime.RequiredError(
                'dashboardUuid',
                'Required parameter "dashboardUuid" was null or undefined when calling deleteDashboardTable().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling deleteDashboardTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteDashboardTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/{dashboard_uuid}/tables/{table_uuid}/delete`.replace(`{${"dashboard_uuid"}}`, encodeURIComponent(String(requestParameters['dashboardUuid']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Table From Dashboard
     */
    async deleteDashboardTable(requestParameters: DeleteDashboardTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteDashboardTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Shared Table
     */
    async deleteSharedTableRaw(requestParameters: DeleteSharedTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling deleteSharedTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling deleteSharedTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/shared/tables/{table_uuid}`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete Shared Table
     */
    async deleteSharedTable(requestParameters: DeleteSharedTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteSharedTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download Shared Table As Csv
     */
    async downloadSharedTableAsCsvRaw(requestParameters: DownloadSharedTableAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling downloadSharedTableAsCsv().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling downloadSharedTableAsCsv().'
            );
        }

        if (requestParameters['downloadCSVParams'] == null) {
            throw new runtime.RequiredError(
                'downloadCSVParams',
                'Required parameter "downloadCSVParams" was null or undefined when calling downloadSharedTableAsCsv().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/shared/tables/{table_uuid}/download`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DownloadCSVParamsToJSON(requestParameters['downloadCSVParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Download Shared Table As Csv
     */
    async downloadSharedTableAsCsv(requestParameters: DownloadSharedTableAsCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.downloadSharedTableAsCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export Dashboard Table Csv
     */
    async downloadTableCSVRaw(requestParameters: DownloadTableCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling downloadTableCSV().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling downloadTableCSV().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/table/{table_uuid}/csv`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export Dashboard Table Csv
     */
    async downloadTableCSV(requestParameters: DownloadTableCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.downloadTableCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export Dashboard Table Xls
     */
    async downloadTableXLSRaw(requestParameters: DownloadTableXLSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling downloadTableXLS().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling downloadTableXLS().'
            );
        }

        if (requestParameters['downloadTableXlsParams'] == null) {
            throw new runtime.RequiredError(
                'downloadTableXlsParams',
                'Required parameter "downloadTableXlsParams" was null or undefined when calling downloadTableXLS().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/table/{table_uuid}/xls`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DownloadTableXlsParamsToJSON(requestParameters['downloadTableXlsParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export Dashboard Table Xls
     */
    async downloadTableXLS(requestParameters: DownloadTableXLSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.downloadTableXLSRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate Dashboard
     */
    async duplicateDashboardRaw(requestParameters: DuplicateDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DuplicateDashboardResponse>> {
        if (requestParameters['dashboardUuid'] == null) {
            throw new runtime.RequiredError(
                'dashboardUuid',
                'Required parameter "dashboardUuid" was null or undefined when calling duplicateDashboard().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling duplicateDashboard().'
            );
        }

        if (requestParameters['duplicateDashboardParams'] == null) {
            throw new runtime.RequiredError(
                'duplicateDashboardParams',
                'Required parameter "duplicateDashboardParams" was null or undefined when calling duplicateDashboard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/{dashboard_uuid}/duplicate`.replace(`{${"dashboard_uuid"}}`, encodeURIComponent(String(requestParameters['dashboardUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DuplicateDashboardParamsToJSON(requestParameters['duplicateDashboardParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DuplicateDashboardResponseFromJSON(jsonValue));
    }

    /**
     * Duplicate Dashboard
     */
    async duplicateDashboard(requestParameters: DuplicateDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DuplicateDashboardResponse> {
        const response = await this.duplicateDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Duplicate Table
     */
    async duplicateTableRaw(requestParameters: DuplicateTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Table>> {
        if (requestParameters['dashboardUuid'] == null) {
            throw new runtime.RequiredError(
                'dashboardUuid',
                'Required parameter "dashboardUuid" was null or undefined when calling duplicateTable().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling duplicateTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling duplicateTable().'
            );
        }

        if (requestParameters['duplicateTableParams'] == null) {
            throw new runtime.RequiredError(
                'duplicateTableParams',
                'Required parameter "duplicateTableParams" was null or undefined when calling duplicateTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/{dashboard_uuid}/tables/{table_uuid}/duplicate`.replace(`{${"dashboard_uuid"}}`, encodeURIComponent(String(requestParameters['dashboardUuid']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DuplicateTableParamsToJSON(requestParameters['duplicateTableParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TableFromJSON(jsonValue));
    }

    /**
     * Duplicate Table
     */
    async duplicateTable(requestParameters: DuplicateTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Table> {
        const response = await this.duplicateTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggle Favorite Shared Table
     */
    async favoriteSharedTableRaw(requestParameters: FavoriteSharedTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling favoriteSharedTable().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling favoriteSharedTable().'
            );
        }

        if (requestParameters['favoriteSharedTableParams'] == null) {
            throw new runtime.RequiredError(
                'favoriteSharedTableParams',
                'Required parameter "favoriteSharedTableParams" was null or undefined when calling favoriteSharedTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/shared/tables/{table_uuid}/favorite`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteSharedTableParamsToJSON(requestParameters['favoriteSharedTableParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Toggle Favorite Shared Table
     */
    async favoriteSharedTable(requestParameters: FavoriteSharedTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.favoriteSharedTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Dashboard Details
     */
    async fetchDashboardDetailsRaw(requestParameters: FetchDashboardDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardDetailsResponse>> {
        if (requestParameters['dashboardUuid'] == null) {
            throw new runtime.RequiredError(
                'dashboardUuid',
                'Required parameter "dashboardUuid" was null or undefined when calling fetchDashboardDetails().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchDashboardDetails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['includeTablesData'] != null) {
            queryParameters['include_tables_data'] = requestParameters['includeTablesData'];
        }

        if (requestParameters['snapshotUuid'] != null) {
            queryParameters['snapshot_uuid'] = requestParameters['snapshotUuid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/{dashboard_uuid}`.replace(`{${"dashboard_uuid"}}`, encodeURIComponent(String(requestParameters['dashboardUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Fetch Dashboard Details
     */
    async fetchDashboardDetails(requestParameters: FetchDashboardDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardDetailsResponse> {
        const response = await this.fetchDashboardDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch Table Data
     */
    async fetchTableDataRaw(requestParameters: FetchTableDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TableDataResponse>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling fetchTableData().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling fetchTableData().'
            );
        }

        if (requestParameters['tableDataParams'] == null) {
            throw new runtime.RequiredError(
                'tableDataParams',
                'Required parameter "tableDataParams" was null or undefined when calling fetchTableData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/table/{table_uuid}`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TableDataParamsToJSON(requestParameters['tableDataParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TableDataResponseFromJSON(jsonValue));
    }

    /**
     * Fetch Table Data
     */
    async fetchTableData(requestParameters: FetchTableDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TableDataResponse> {
        const response = await this.fetchTableDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Shared Tables From Origin Table
     */
    async listSharedTablesFromOriginTableRaw(requestParameters: ListSharedTablesFromOriginTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SharedTable>>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling listSharedTablesFromOriginTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/shared/tables/{table_uuid}`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SharedTableFromJSON));
    }

    /**
     * List Shared Tables From Origin Table
     */
    async listSharedTablesFromOriginTable(requestParameters: ListSharedTablesFromOriginTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SharedTable>> {
        const response = await this.listSharedTablesFromOriginTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Workspace Dashboards
     */
    async listWorkspaceDashboardsRaw(requestParameters: ListWorkspaceDashboardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Dashboard>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling listWorkspaceDashboards().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DashboardFromJSON));
    }

    /**
     * List Workspace Dashboards
     */
    async listWorkspaceDashboards(requestParameters: ListWorkspaceDashboardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Dashboard>> {
        const response = await this.listWorkspaceDashboardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Workspace Shared Tables
     */
    async listWorkspaceSharedTablesRaw(requestParameters: ListWorkspaceSharedTablesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SharedTable>>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling listWorkspaceSharedTables().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/shared/tables`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SharedTableFromJSON));
    }

    /**
     * List Workspace Shared Tables
     */
    async listWorkspaceSharedTables(requestParameters: ListWorkspaceSharedTablesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SharedTable>> {
        const response = await this.listWorkspaceSharedTablesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove Metric From Table
     */
    async removeMetricFromTableRaw(requestParameters: RemoveMetricFromTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TableDataResponse>> {
        if (requestParameters['dashboardUuid'] == null) {
            throw new runtime.RequiredError(
                'dashboardUuid',
                'Required parameter "dashboardUuid" was null or undefined when calling removeMetricFromTable().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling removeMetricFromTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling removeMetricFromTable().'
            );
        }

        if (requestParameters['addMetricToTableParams'] == null) {
            throw new runtime.RequiredError(
                'addMetricToTableParams',
                'Required parameter "addMetricToTableParams" was null or undefined when calling removeMetricFromTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/{dashboard_uuid}/tables/{table_uuid}/metrics/remove`.replace(`{${"dashboard_uuid"}}`, encodeURIComponent(String(requestParameters['dashboardUuid']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMetricToTableParamsToJSON(requestParameters['addMetricToTableParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TableDataResponseFromJSON(jsonValue));
    }

    /**
     * Remove Metric From Table
     */
    async removeMetricFromTable(requestParameters: RemoveMetricFromTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TableDataResponse> {
        const response = await this.removeMetricFromTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Dashboard Contributors
     */
    async setDashboardContributorsRaw(requestParameters: SetDashboardContributorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Dashboard>> {
        if (requestParameters['dashboardUuid'] == null) {
            throw new runtime.RequiredError(
                'dashboardUuid',
                'Required parameter "dashboardUuid" was null or undefined when calling setDashboardContributors().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling setDashboardContributors().'
            );
        }

        if (requestParameters['setDashboardContributorsParams'] == null) {
            throw new runtime.RequiredError(
                'setDashboardContributorsParams',
                'Required parameter "setDashboardContributorsParams" was null or undefined when calling setDashboardContributors().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/{dashboard_uuid}/contributors`.replace(`{${"dashboard_uuid"}}`, encodeURIComponent(String(requestParameters['dashboardUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDashboardContributorsParamsToJSON(requestParameters['setDashboardContributorsParams']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardFromJSON(jsonValue));
    }

    /**
     * Set Dashboard Contributors
     */
    async setDashboardContributors(requestParameters: SetDashboardContributorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Dashboard> {
        const response = await this.setDashboardContributorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Shared Table Owners
     */
    async setSharedTableOwnersRaw(requestParameters: SetSharedTableOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling setSharedTableOwners().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling setSharedTableOwners().'
            );
        }

        if (requestParameters['setSharedTableOwnersParams'] == null) {
            throw new runtime.RequiredError(
                'setSharedTableOwnersParams',
                'Required parameter "setSharedTableOwnersParams" was null or undefined when calling setSharedTableOwners().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/shared/tables/{table_uuid}/owners`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetSharedTableOwnersParamsToJSON(requestParameters['setSharedTableOwnersParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set Shared Table Owners
     */
    async setSharedTableOwners(requestParameters: SetSharedTableOwnersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.setSharedTableOwnersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Share Table
     */
    async shareTableRaw(requestParameters: ShareTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling shareTable().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling shareTable().'
            );
        }

        if (requestParameters['shareTableParams'] == null) {
            throw new runtime.RequiredError(
                'shareTableParams',
                'Required parameter "shareTableParams" was null or undefined when calling shareTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/shared/tables/{table_uuid}/share`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareTableParamsToJSON(requestParameters['shareTableParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Share Table
     */
    async shareTable(requestParameters: ShareTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.shareTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sync Shared Table
     */
    async syncSharedTableRaw(requestParameters: SyncSharedTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling syncSharedTable().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling syncSharedTable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/shared/tables/{table_uuid}/sync`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Sync Shared Table
     */
    async syncSharedTable(requestParameters: SyncSharedTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.syncSharedTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Favorite
     */
    async toggleFavoriteRaw(requestParameters: ToggleFavoriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling toggleFavorite().'
            );
        }

        if (requestParameters['favoriteDashboardParams'] == null) {
            throw new runtime.RequiredError(
                'favoriteDashboardParams',
                'Required parameter "favoriteDashboardParams" was null or undefined when calling toggleFavorite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/favorite`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteDashboardParamsToJSON(requestParameters['favoriteDashboardParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set Favorite
     */
    async toggleFavorite(requestParameters: ToggleFavoriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.toggleFavoriteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Dashboard
     */
    async updateDashboardRaw(requestParameters: UpdateDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['dashboardUuid'] == null) {
            throw new runtime.RequiredError(
                'dashboardUuid',
                'Required parameter "dashboardUuid" was null or undefined when calling updateDashboard().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateDashboard().'
            );
        }

        if (requestParameters['updateDashboardParams'] == null) {
            throw new runtime.RequiredError(
                'updateDashboardParams',
                'Required parameter "updateDashboardParams" was null or undefined when calling updateDashboard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/update/{dashboard_uuid}`.replace(`{${"dashboard_uuid"}}`, encodeURIComponent(String(requestParameters['dashboardUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDashboardParamsToJSON(requestParameters['updateDashboardParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Dashboard
     */
    async updateDashboard(requestParameters: UpdateDashboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Shared Table Details
     */
    async updateSharedTableDetailsRaw(requestParameters: UpdateSharedTableDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateSharedTableDetails().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling updateSharedTableDetails().'
            );
        }

        if (requestParameters['updateSharedTableDetailsParams'] == null) {
            throw new runtime.RequiredError(
                'updateSharedTableDetailsParams',
                'Required parameter "updateSharedTableDetailsParams" was null or undefined when calling updateSharedTableDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/shared/tables/{table_uuid}/update`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSharedTableDetailsParamsToJSON(requestParameters['updateSharedTableDetailsParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Shared Table Details
     */
    async updateSharedTableDetails(requestParameters: UpdateSharedTableDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateSharedTableDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Shared Table Schema
     */
    async updateSharedTableSchemaRaw(requestParameters: UpdateSharedTableSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateSharedTableSchema().'
            );
        }

        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling updateSharedTableSchema().'
            );
        }

        if (requestParameters['updateSchemaParams'] == null) {
            throw new runtime.RequiredError(
                'updateSchemaParams',
                'Required parameter "updateSchemaParams" was null or undefined when calling updateSharedTableSchema().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/shared/tables/{table_uuid}/schema`.replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))).replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSchemaParamsToJSON(requestParameters['updateSchemaParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Shared Table Schema
     */
    async updateSharedTableSchema(requestParameters: UpdateSharedTableSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateSharedTableSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Table Attributes
     */
    async updateTableAttributesRaw(requestParameters: UpdateTableAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['tableUuid'] == null) {
            throw new runtime.RequiredError(
                'tableUuid',
                'Required parameter "tableUuid" was null or undefined when calling updateTableAttributes().'
            );
        }

        if (requestParameters['workspaceCode'] == null) {
            throw new runtime.RequiredError(
                'workspaceCode',
                'Required parameter "workspaceCode" was null or undefined when calling updateTableAttributes().'
            );
        }

        if (requestParameters['updateTableAttributesParams'] == null) {
            throw new runtime.RequiredError(
                'updateTableAttributesParams',
                'Required parameter "updateTableAttributesParams" was null or undefined when calling updateTableAttributes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearerWithCookie", []);
        }

        const response = await this.request({
            path: `/{workspace_code}/dashboards/tables/{table_uuid}/attributes/update`.replace(`{${"table_uuid"}}`, encodeURIComponent(String(requestParameters['tableUuid']))).replace(`{${"workspace_code"}}`, encodeURIComponent(String(requestParameters['workspaceCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTableAttributesParamsToJSON(requestParameters['updateTableAttributesParams']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update Table Attributes
     */
    async updateTableAttributes(requestParameters: UpdateTableAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateTableAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
