/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateMappingsFromFileParams
 */
export interface CreateMappingsFromFileParams {
    /**
     * 
     * @type {string}
     * @memberof CreateMappingsFromFileParams
     */
    uploaderUuid: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingsFromFileParams
     */
    originCol: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMappingsFromFileParams
     */
    destCols: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingsFromFileParams
     */
    op: CreateMappingsFromFileParamsOpEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateMappingsFromFileParams
     */
    name: string;
}


/**
 * @export
 */
export const CreateMappingsFromFileParamsOpEnum = {
    Eq: 'eq',
    Contains: 'contains',
    StartsWith: 'startsWith',
    EndsWith: 'endsWith',
    Neq: 'neq',
    Before: 'before',
    After: 'after'
} as const;
export type CreateMappingsFromFileParamsOpEnum = typeof CreateMappingsFromFileParamsOpEnum[keyof typeof CreateMappingsFromFileParamsOpEnum];


/**
 * Check if a given object implements the CreateMappingsFromFileParams interface.
 */
export function instanceOfCreateMappingsFromFileParams(value: object): boolean {
    if (!('uploaderUuid' in value)) return false;
    if (!('originCol' in value)) return false;
    if (!('destCols' in value)) return false;
    if (!('op' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function CreateMappingsFromFileParamsFromJSON(json: any): CreateMappingsFromFileParams {
    return CreateMappingsFromFileParamsFromJSONTyped(json, false);
}

export function CreateMappingsFromFileParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMappingsFromFileParams {
    if (json == null) {
        return json;
    }
    return {
        
        'uploaderUuid': json['uploader_uuid'],
        'originCol': json['origin_col'],
        'destCols': json['dest_cols'],
        'op': json['op'],
        'name': json['name'],
    };
}

export function CreateMappingsFromFileParamsToJSON(value?: CreateMappingsFromFileParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uploader_uuid': value['uploaderUuid'],
        'origin_col': value['originCol'],
        'dest_cols': value['destCols'],
        'op': value['op'],
        'name': value['name'],
    };
}

