/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MappingOutput } from './MappingOutput';
import {
    MappingOutputFromJSON,
    MappingOutputFromJSONTyped,
    MappingOutputToJSON,
} from './MappingOutput';

/**
 * 
 * @export
 * @interface MappingGroup
 */
export interface MappingGroup {
    /**
     * 
     * @type {string}
     * @memberof MappingGroup
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof MappingGroup
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof MappingGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MappingGroup
     */
    code: string;
    /**
     * 
     * @type {Array<MappingOutput>}
     * @memberof MappingGroup
     */
    mappings?: Array<MappingOutput>;
    /**
     * 
     * @type {string}
     * @memberof MappingGroup
     */
    uploaderUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingGroup
     */
    originColumn?: string;
    /**
     * 
     * @type {string}
     * @memberof MappingGroup
     */
    operation?: MappingGroupOperationEnum;
}


/**
 * @export
 */
export const MappingGroupOperationEnum = {
    Eq: 'eq',
    Contains: 'contains',
    StartsWith: 'startsWith',
    EndsWith: 'endsWith',
    Neq: 'neq',
    Before: 'before',
    After: 'after'
} as const;
export type MappingGroupOperationEnum = typeof MappingGroupOperationEnum[keyof typeof MappingGroupOperationEnum];


/**
 * Check if a given object implements the MappingGroup interface.
 */
export function instanceOfMappingGroup(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('code' in value)) return false;
    return true;
}

export function MappingGroupFromJSON(json: any): MappingGroup {
    return MappingGroupFromJSONTyped(json, false);
}

export function MappingGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): MappingGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'name': json['name'],
        'code': json['code'],
        'mappings': json['mappings'] == null ? undefined : ((json['mappings'] as Array<any>).map(MappingOutputFromJSON)),
        'uploaderUuid': json['uploader_uuid'] == null ? undefined : json['uploader_uuid'],
        'originColumn': json['origin_column'] == null ? undefined : json['origin_column'],
        'operation': json['operation'] == null ? undefined : json['operation'],
    };
}

export function MappingGroupToJSON(value?: MappingGroup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'name': value['name'],
        'code': value['code'],
        'mappings': value['mappings'] == null ? undefined : ((value['mappings'] as Array<any>).map(MappingOutputToJSON)),
        'uploader_uuid': value['uploaderUuid'],
        'origin_column': value['originColumn'],
        'operation': value['operation'],
    };
}

