/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSharedTableDetailsParams
 */
export interface UpdateSharedTableDetailsParams {
    /**
     * 
     * @type {object}
     * @memberof UpdateSharedTableDetailsParams
     */
    updates: object;
}

/**
 * Check if a given object implements the UpdateSharedTableDetailsParams interface.
 */
export function instanceOfUpdateSharedTableDetailsParams(value: object): boolean {
    if (!('updates' in value)) return false;
    return true;
}

export function UpdateSharedTableDetailsParamsFromJSON(json: any): UpdateSharedTableDetailsParams {
    return UpdateSharedTableDetailsParamsFromJSONTyped(json, false);
}

export function UpdateSharedTableDetailsParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSharedTableDetailsParams {
    if (json == null) {
        return json;
    }
    return {
        
        'updates': json['updates'],
    };
}

export function UpdateSharedTableDetailsParamsToJSON(value?: UpdateSharedTableDetailsParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'updates': value['updates'],
    };
}

