/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateExpressionParams
 */
export interface ValidateExpressionParams {
    /**
     * 
     * @type {string}
     * @memberof ValidateExpressionParams
     */
    formula: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateExpressionParams
     */
    versionUuid: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateExpressionParams
     */
    scenarioUuid: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ValidateExpressionParams
     */
    dimensionValues?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof ValidateExpressionParams
     */
    periodStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof ValidateExpressionParams
     */
    periodEnd: Date;
    /**
     * 
     * @type {string}
     * @memberof ValidateExpressionParams
     */
    expressionUuid?: string;
}

/**
 * Check if a given object implements the ValidateExpressionParams interface.
 */
export function instanceOfValidateExpressionParams(value: object): boolean {
    if (!('formula' in value)) return false;
    if (!('versionUuid' in value)) return false;
    if (!('scenarioUuid' in value)) return false;
    if (!('periodStart' in value)) return false;
    if (!('periodEnd' in value)) return false;
    return true;
}

export function ValidateExpressionParamsFromJSON(json: any): ValidateExpressionParams {
    return ValidateExpressionParamsFromJSONTyped(json, false);
}

export function ValidateExpressionParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateExpressionParams {
    if (json == null) {
        return json;
    }
    return {
        
        'formula': json['formula'],
        'versionUuid': json['version_uuid'],
        'scenarioUuid': json['scenario_uuid'],
        'dimensionValues': json['dimension_values'] == null ? undefined : json['dimension_values'],
        'periodStart': (new Date(json['period_start'])),
        'periodEnd': (new Date(json['period_end'])),
        'expressionUuid': json['expression_uuid'] == null ? undefined : json['expression_uuid'],
    };
}

export function ValidateExpressionParamsToJSON(value?: ValidateExpressionParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'formula': value['formula'],
        'version_uuid': value['versionUuid'],
        'scenario_uuid': value['scenarioUuid'],
        'dimension_values': value['dimensionValues'],
        'period_start': ((value['periodStart']).toISOString()),
        'period_end': ((value['periodEnd']).toISOString()),
        'expression_uuid': value['expressionUuid'],
    };
}

