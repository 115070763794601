import { defineStore } from "pinia";
import { useConfig } from "./useConfig";
import { useAuthApi, useWorkspaceApi } from "./useApi";

export interface AiResponse {
  answer?: string;
  question: string;
}

const config = useConfig();

const useChat = (conversationUuid: string) => defineStore(conversationUuid, {
  state: () => ({
    messages: [] as string[],
    ws: new WebSocket(`${config.wsURL}/${useWorkspaceApi()._current?.code}/ai/chat/${conversationUuid}`),
    conversationUuid: conversationUuid,
    answer: `Hello, ${useAuthApi().user?.name} what can I help you with?`,
    history: [{ answer:`Hello, ${useAuthApi().user?.name} what can I help you with?`, question: "" }] as AiResponse[],
  }),
  actions: {
    async startConversation() {
      this.connect();
    },
    setConversation(conversationUuid: string) {
      this.conversationUuid = conversationUuid
    },
    addMessage(message: string) {
      this.messages.push(message);
    },
    sendMessage({question, context, entityUuid, extraData}: {question: string, context?: string, entityUuid?: string, extraData?: Record<string, string>}) {
      this.ws.send(JSON.stringify({ question, context, entityUuid, extraData}));
    },
    connect() {
      this.ws.onopen = () => {
        console.log('connected');
      };
      this.ws.onmessage = (message: MessageEvent) => {
        this.addMessage(message.data);
      };
      this.ws.onclose = (ev: Event) => {
        console.log('disconnected');
        console.log(ev);
      };
      this.ws.onerror = (ev: Event) => {
        console.log('error');
        console.log(ev);
        // Get cause of error
      }
    },
    disconnect() {
      console.log("DISCONNECTED");
      this.ws.close();
    },
  },
})();

export default useChat;
