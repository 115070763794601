/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Dimensions } from './Dimensions';
import {
    DimensionsFromJSON,
    DimensionsFromJSONTyped,
    DimensionsToJSON,
} from './Dimensions';
import type { Period } from './Period';
import {
    PeriodFromJSON,
    PeriodFromJSONTyped,
    PeriodToJSON,
} from './Period';
import type { PeriodSeparator } from './PeriodSeparator';
import {
    PeriodSeparatorFromJSON,
    PeriodSeparatorFromJSONTyped,
    PeriodSeparatorToJSON,
} from './PeriodSeparator';

/**
 * 
 * @export
 * @interface UpdateTableAttributesParams
 */
export interface UpdateTableAttributesParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateTableAttributesParams
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTableAttributesParams
     */
    title?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateTableAttributesParams
     */
    filters?: Array<object>;
    /**
     * 
     * @type {Dimensions}
     * @memberof UpdateTableAttributesParams
     */
    dimensions?: Dimensions;
    /**
     * 
     * @type {Period}
     * @memberof UpdateTableAttributesParams
     */
    period?: Period;
    /**
     * 
     * @type {PeriodSeparator}
     * @memberof UpdateTableAttributesParams
     */
    periodSeparator?: PeriodSeparator;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTableAttributesParams
     */
    scenarios?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTableAttributesParams
     */
    metrics?: Array<string>;
    /**
     * 
     * @type {Array<any>}
     * @memberof UpdateTableAttributesParams
     */
    structure?: Array<any>;
    /**
     * 
     * @type {object}
     * @memberof UpdateTableAttributesParams
     */
    metricsDimensions?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateTableAttributesParams
     */
    yearTotals?: UpdateTableAttributesParamsYearTotalsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTableAttributesParams
     */
    totalDeviation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTableAttributesParams
     */
    percentageDeviation?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTableAttributesParams
     */
    rowDimensions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTableAttributesParams
     */
    colDimensions?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTableAttributesParams
     */
    includePeriod?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTableAttributesParams
     */
    removeColSubtotals?: boolean;
}


/**
 * @export
 */
export const UpdateTableAttributesParamsYearTotalsEnum = {
    Natural: 'natural',
    Fiscal: 'fiscal',
    Null: 'null'
} as const;
export type UpdateTableAttributesParamsYearTotalsEnum = typeof UpdateTableAttributesParamsYearTotalsEnum[keyof typeof UpdateTableAttributesParamsYearTotalsEnum];


/**
 * Check if a given object implements the UpdateTableAttributesParams interface.
 */
export function instanceOfUpdateTableAttributesParams(value: object): boolean {
    return true;
}

export function UpdateTableAttributesParamsFromJSON(json: any): UpdateTableAttributesParams {
    return UpdateTableAttributesParamsFromJSONTyped(json, false);
}

export function UpdateTableAttributesParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTableAttributesParams {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'title': json['title'] == null ? undefined : json['title'],
        'filters': json['filters'] == null ? undefined : json['filters'],
        'dimensions': json['dimensions'] == null ? undefined : DimensionsFromJSON(json['dimensions']),
        'period': json['period'] == null ? undefined : PeriodFromJSON(json['period']),
        'periodSeparator': json['period_separator'] == null ? undefined : PeriodSeparatorFromJSON(json['period_separator']),
        'scenarios': json['scenarios'] == null ? undefined : json['scenarios'],
        'metrics': json['metrics'] == null ? undefined : json['metrics'],
        'structure': json['structure'] == null ? undefined : json['structure'],
        'metricsDimensions': json['metrics_dimensions'] == null ? undefined : json['metrics_dimensions'],
        'yearTotals': json['year_totals'] == null ? undefined : json['year_totals'],
        'totalDeviation': json['total_deviation'] == null ? undefined : json['total_deviation'],
        'percentageDeviation': json['percentage_deviation'] == null ? undefined : json['percentage_deviation'],
        'rowDimensions': json['row_dimensions'] == null ? undefined : json['row_dimensions'],
        'colDimensions': json['col_dimensions'] == null ? undefined : json['col_dimensions'],
        'includePeriod': json['include_period'] == null ? undefined : json['include_period'],
        'removeColSubtotals': json['remove_col_subtotals'] == null ? undefined : json['remove_col_subtotals'],
    };
}

export function UpdateTableAttributesParamsToJSON(value?: UpdateTableAttributesParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'title': value['title'],
        'filters': value['filters'],
        'dimensions': DimensionsToJSON(value['dimensions']),
        'period': PeriodToJSON(value['period']),
        'period_separator': PeriodSeparatorToJSON(value['periodSeparator']),
        'scenarios': value['scenarios'],
        'metrics': value['metrics'],
        'structure': value['structure'],
        'metrics_dimensions': value['metricsDimensions'],
        'year_totals': value['yearTotals'],
        'total_deviation': value['totalDeviation'],
        'percentage_deviation': value['percentageDeviation'],
        'row_dimensions': value['rowDimensions'],
        'col_dimensions': value['colDimensions'],
        'include_period': value['includePeriod'],
        'remove_col_subtotals': value['removeColSubtotals'],
    };
}

