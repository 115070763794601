/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CellsInner } from './CellsInner';
import {
    CellsInnerFromJSON,
    CellsInnerFromJSONTyped,
    CellsInnerToJSON,
} from './CellsInner';

/**
 * 
 * @export
 * @interface PivotTableRow
 */
export interface PivotTableRow {
    /**
     * 
     * @type {string}
     * @memberof PivotTableRow
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PivotTableRow
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof PivotTableRow
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PivotTableRow
     */
    parentId?: string;
    /**
     * 
     * @type {Array<PivotTableRow>}
     * @memberof PivotTableRow
     */
    children?: Array<PivotTableRow>;
    /**
     * 
     * @type {Array<CellsInner>}
     * @memberof PivotTableRow
     */
    cells: Array<CellsInner>;
    /**
     * 
     * @type {number}
     * @memberof PivotTableRow
     */
    depth?: number;
    /**
     * 
     * @type {string}
     * @memberof PivotTableRow
     */
    unit?: PivotTableRowUnitEnum;
}


/**
 * @export
 */
export const PivotTableRowUnitEnum = {
    CurrencyEuro: 'currency_euro',
    CurrencyDollar: 'currency_dollar',
    Count: 'count',
    Percentage: 'percentage',
    Date: 'date'
} as const;
export type PivotTableRowUnitEnum = typeof PivotTableRowUnitEnum[keyof typeof PivotTableRowUnitEnum];


/**
 * Check if a given object implements the PivotTableRow interface.
 */
export function instanceOfPivotTableRow(value: object): boolean {
    if (!('label' in value)) return false;
    if (!('id' in value)) return false;
    if (!('cells' in value)) return false;
    return true;
}

export function PivotTableRowFromJSON(json: any): PivotTableRow {
    return PivotTableRowFromJSONTyped(json, false);
}

export function PivotTableRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotTableRow {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
        'label': json['label'],
        'id': json['id_'],
        'parentId': json['parent_id'] == null ? undefined : json['parent_id'],
        'children': json['children'] == null ? undefined : ((json['children'] as Array<any>).map(PivotTableRowFromJSON)),
        'cells': ((json['cells'] as Array<any>).map(CellsInnerFromJSON)),
        'depth': json['depth'] == null ? undefined : json['depth'],
        'unit': json['unit'] == null ? undefined : json['unit'],
    };
}

export function PivotTableRowToJSON(value?: PivotTableRow | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'label': value['label'],
        'id_': value['id'],
        'parent_id': value['parentId'],
        'children': value['children'] == null ? undefined : ((value['children'] as Array<any>).map(PivotTableRowToJSON)),
        'cells': ((value['cells'] as Array<any>).map(CellsInnerToJSON)),
        'depth': value['depth'],
        'unit': value['unit'],
    };
}

