/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShareTableParams
 */
export interface ShareTableParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareTableParams
     */
    targetWorkspacesUuids: Array<string>;
}

/**
 * Check if a given object implements the ShareTableParams interface.
 */
export function instanceOfShareTableParams(value: object): boolean {
    if (!('targetWorkspacesUuids' in value)) return false;
    return true;
}

export function ShareTableParamsFromJSON(json: any): ShareTableParams {
    return ShareTableParamsFromJSONTyped(json, false);
}

export function ShareTableParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareTableParams {
    if (json == null) {
        return json;
    }
    return {
        
        'targetWorkspacesUuids': json['target_workspaces_uuids'],
    };
}

export function ShareTableParamsToJSON(value?: ShareTableParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'target_workspaces_uuids': value['targetWorkspacesUuids'],
    };
}

