/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DuplicateDashboardResponse
 */
export interface DuplicateDashboardResponse {
    /**
     * 
     * @type {string}
     * @memberof DuplicateDashboardResponse
     */
    newDashboard: string;
    /**
     * 
     * @type {object}
     * @memberof DuplicateDashboardResponse
     */
    mapping: object;
}

/**
 * Check if a given object implements the DuplicateDashboardResponse interface.
 */
export function instanceOfDuplicateDashboardResponse(value: object): boolean {
    if (!('newDashboard' in value)) return false;
    if (!('mapping' in value)) return false;
    return true;
}

export function DuplicateDashboardResponseFromJSON(json: any): DuplicateDashboardResponse {
    return DuplicateDashboardResponseFromJSONTyped(json, false);
}

export function DuplicateDashboardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DuplicateDashboardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'newDashboard': json['new_dashboard'],
        'mapping': json['mapping'],
    };
}

export function DuplicateDashboardResponseToJSON(value?: DuplicateDashboardResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'new_dashboard': value['newDashboard'],
        'mapping': value['mapping'],
    };
}

