/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Metric } from './Metric';
import {
    MetricFromJSON,
    MetricFromJSONTyped,
    MetricToJSON,
} from './Metric';
import type { Variable } from './Variable';
import {
    VariableFromJSON,
    VariableFromJSONTyped,
    VariableToJSON,
} from './Variable';

/**
 * 
 * @export
 * @interface SnapshotMetadata
 */
export interface SnapshotMetadata {
    /**
     * 
     * @type {{ [key: string]: Metric; }}
     * @memberof SnapshotMetadata
     */
    metrics: { [key: string]: Metric; };
    /**
     * 
     * @type {{ [key: string]: Variable; }}
     * @memberof SnapshotMetadata
     */
    variables: { [key: string]: Variable; };
}

/**
 * Check if a given object implements the SnapshotMetadata interface.
 */
export function instanceOfSnapshotMetadata(value: object): boolean {
    if (!('metrics' in value)) return false;
    if (!('variables' in value)) return false;
    return true;
}

export function SnapshotMetadataFromJSON(json: any): SnapshotMetadata {
    return SnapshotMetadataFromJSONTyped(json, false);
}

export function SnapshotMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SnapshotMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'metrics': (mapValues(json['metrics'], MetricFromJSON)),
        'variables': (mapValues(json['variables'], VariableFromJSON)),
    };
}

export function SnapshotMetadataToJSON(value?: SnapshotMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'metrics': (mapValues(value['metrics'], MetricToJSON)),
        'variables': (mapValues(value['variables'], VariableToJSON)),
    };
}

