/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MetricOwner } from './MetricOwner';
import {
    MetricOwnerFromJSON,
    MetricOwnerFromJSONTyped,
    MetricOwnerToJSON,
} from './MetricOwner';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Metric
 */
export interface Metric {
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    unit?: MetricUnitEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Metric
     */
    usesDefaultUnit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Metric
     */
    lowerIsBetter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    categoryUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    formula?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Metric
     */
    scenarioFormulas?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    createdByUuid: string;
    /**
     * 
     * @type {Date}
     * @memberof Metric
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Metric
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<User>}
     * @memberof Metric
     */
    favoritedBy?: Array<User>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Metric
     */
    favoritedByAdmins?: Array<User>;
    /**
     * 
     * @type {Array<MetricOwner>}
     * @memberof Metric
     */
    owners?: Array<MetricOwner>;
    /**
     * 
     * @type {boolean}
     * @memberof Metric
     */
    isPublic: boolean;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    timeAggregateFunction?: MetricTimeAggregateFunctionEnum;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    dimAggregateFunction?: MetricDimAggregateFunctionEnum;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    granularity?: MetricGranularityEnum;
    /**
     * 
     * @type {number}
     * @memberof Metric
     */
    fillValue?: number;
}


/**
 * @export
 */
export const MetricUnitEnum = {
    CurrencyEuro: 'currency_euro',
    CurrencyDollar: 'currency_dollar',
    Count: 'count',
    Percentage: 'percentage',
    Date: 'date'
} as const;
export type MetricUnitEnum = typeof MetricUnitEnum[keyof typeof MetricUnitEnum];

/**
 * @export
 */
export const MetricTimeAggregateFunctionEnum = {
    Sum: 'sum',
    Avg: 'avg',
    First: 'first',
    Last: 'last',
    Count: 'count',
    Min: 'min',
    Max: 'max',
    Formula: 'formula',
    Cumsum: 'cumsum',
    CumsumYear: 'cumsum_year',
    CumsumQuarter: 'cumsum_quarter',
    Null: 'null'
} as const;
export type MetricTimeAggregateFunctionEnum = typeof MetricTimeAggregateFunctionEnum[keyof typeof MetricTimeAggregateFunctionEnum];

/**
 * @export
 */
export const MetricDimAggregateFunctionEnum = {
    Sum: 'sum',
    Avg: 'avg',
    Count: 'count',
    Min: 'min',
    Max: 'max',
    Formula: 'formula',
    Null: 'null'
} as const;
export type MetricDimAggregateFunctionEnum = typeof MetricDimAggregateFunctionEnum[keyof typeof MetricDimAggregateFunctionEnum];

/**
 * @export
 */
export const MetricGranularityEnum = {
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Quarter: 'quarter',
    Year: 'year'
} as const;
export type MetricGranularityEnum = typeof MetricGranularityEnum[keyof typeof MetricGranularityEnum];


/**
 * Check if a given object implements the Metric interface.
 */
export function instanceOfMetric(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('createdByUuid' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('isPublic' in value)) return false;
    return true;
}

export function MetricFromJSON(json: any): Metric {
    return MetricFromJSONTyped(json, false);
}

export function MetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): Metric {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'workspaceUuid': json['workspace_uuid'],
        'unit': json['unit'] == null ? undefined : json['unit'],
        'usesDefaultUnit': json['uses_default_unit'] == null ? undefined : json['uses_default_unit'],
        'name': json['name'],
        'lowerIsBetter': json['lower_is_better'] == null ? undefined : json['lower_is_better'],
        'categoryUuid': json['category_uuid'] == null ? undefined : json['category_uuid'],
        'code': json['code'] == null ? undefined : json['code'],
        'formula': json['formula'] == null ? undefined : json['formula'],
        'scenarioFormulas': json['scenario_formulas'] == null ? undefined : json['scenario_formulas'],
        'description': json['description'] == null ? undefined : json['description'],
        'createdByUuid': json['created_by_uuid'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'favoritedBy': json['favorited_by'] == null ? undefined : ((json['favorited_by'] as Array<any>).map(UserFromJSON)),
        'favoritedByAdmins': json['favorited_by_admins'] == null ? undefined : ((json['favorited_by_admins'] as Array<any>).map(UserFromJSON)),
        'owners': json['owners'] == null ? undefined : ((json['owners'] as Array<any>).map(MetricOwnerFromJSON)),
        'isPublic': json['is_public'],
        'timeAggregateFunction': json['time_aggregate_function'] == null ? undefined : json['time_aggregate_function'],
        'dimAggregateFunction': json['dim_aggregate_function'] == null ? undefined : json['dim_aggregate_function'],
        'granularity': json['granularity'] == null ? undefined : json['granularity'],
        'fillValue': json['fill_value'] == null ? undefined : json['fill_value'],
    };
}

export function MetricToJSON(value?: Metric | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'workspace_uuid': value['workspaceUuid'],
        'unit': value['unit'],
        'uses_default_unit': value['usesDefaultUnit'],
        'name': value['name'],
        'lower_is_better': value['lowerIsBetter'],
        'category_uuid': value['categoryUuid'],
        'code': value['code'],
        'formula': value['formula'],
        'scenario_formulas': value['scenarioFormulas'],
        'description': value['description'],
        'created_by_uuid': value['createdByUuid'],
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'favorited_by': value['favoritedBy'] == null ? undefined : ((value['favoritedBy'] as Array<any>).map(UserToJSON)),
        'favorited_by_admins': value['favoritedByAdmins'] == null ? undefined : ((value['favoritedByAdmins'] as Array<any>).map(UserToJSON)),
        'owners': value['owners'] == null ? undefined : ((value['owners'] as Array<any>).map(MetricOwnerToJSON)),
        'is_public': value['isPublic'],
        'time_aggregate_function': value['timeAggregateFunction'],
        'dim_aggregate_function': value['dimAggregateFunction'],
        'granularity': value['granularity'],
        'fill_value': value['fillValue'],
    };
}

