/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    workspaceUuid: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    createdByUuid: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    type: GroupTypeEnum;
}


/**
 * @export
 */
export const GroupTypeEnum = {
    Dashboard: 'dashboard',
    Model: 'model',
    Database: 'database',
    Datasource: 'datasource'
} as const;
export type GroupTypeEnum = typeof GroupTypeEnum[keyof typeof GroupTypeEnum];


/**
 * Check if a given object implements the Group interface.
 */
export function instanceOfGroup(value: object): boolean {
    if (!('uuid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('workspaceUuid' in value)) return false;
    if (!('createdByUuid' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function GroupFromJSON(json: any): Group {
    return GroupFromJSONTyped(json, false);
}

export function GroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Group {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'color': json['color'] == null ? undefined : json['color'],
        'workspaceUuid': json['workspace_uuid'],
        'createdByUuid': json['created_by_uuid'],
        'type': json['type'],
    };
}

export function GroupToJSON(value?: Group | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'color': value['color'],
        'workspace_uuid': value['workspaceUuid'],
        'created_by_uuid': value['createdByUuid'],
        'type': value['type'],
    };
}

